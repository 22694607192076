import React, { Fragment, useMemo, useState } from 'react';
import { Box, Button, Divider, Grid, useTheme } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { MenuItemProps } from '@core/components/DataTable/CreateList';
import { NavLink, useNavigate } from 'react-router-dom';
import { AlphaContentBox } from '@core/components/Layout';
import { Breadcrumb } from '@core/components/LayoutTheme';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { fetchListUsers, toggleUserStatus } from '../services/userService';
import { MRT_Row } from 'material-react-table';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchAllGroups } from '../../ManageGroup/services/groupService';
import UserInfoModal from '../components/UserInfoModal';
import useSwal from '@core/hooks/useSwal';
import { lazy } from 'react';
import Loadable from '@core/components/LayoutTheme/Loadable';

const CreateList = Loadable(
  lazy(() => import('@core/components/DataTable/CreateList')),
);

const UserListPage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const showSwal = useSwal();
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [rowInfoModal] = useState();

  const { data: groups } = useQuery({
    queryKey: ['groups'],
    queryFn: fetchAllGroups,
    select: (data) =>
      data.map((group) => ({
        value: group.id,
        label: group.name,
      })),
    staleTime: 1000 * 60 * 3, // 3 minutes
  });

  /* const handleOpenModal = (row: any) => {
    setRowInfoModal(row);
    setOpenModal(true);
  }; */

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const columns = useMemo(
    () => [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        size: 50,
        hideFilter: true,
      },
      {
        id: 'name',
        header: 'Nome',
        accessorKey: 'name',
        filterVariant: 'text',
      },
      {
        id: 'email',
        header: 'Email',
        accessorKey: 'email',
        filterVariant: 'text',
        enableClickToCopy: true,
      },
      {
        id: 'group_id',
        header: 'Grupo',
        accessorKey: 'group.name',
        filterVariant: 'select',
        selectOptions: groups || [],
      },
      {
        id: 'active',
        header: 'Situação',
        accessorKey: 'active',
        filterVariant: 'select',
        selectOptions: [
          {
            label: 'Ativo',
            value: '1',
          },
          {
            label: 'Inativo',
            value: '0',
          },
        ],
        Cell: ({ row }: { row: MRT_Row<any> }) => (
          <Box
            component="span"
            sx={{
              padding: '0.25rem',
              borderRadius: '0.25rem',
              backgroundColor: row.original.active
                ? (theme.palette.action as any).success
                : (theme.palette.action as any).error,
              color: '#fff',
            }}
          >
            {row.original.active ? 'Ativo' : 'Inativo'}
          </Box>
        ),
      },
    ],
    [theme, groups],
  );

  const menus: MenuItemProps[] = [
    /* {
      getLabel: () => 'Info',
      action: (row) => {
        handleOpenModal(row);
      },
      getIcon: () => <AddRoundedIcon />,
    }, */
    {
      getLabel: () => 'Editar',
      action: (row) => navigate(`/accessControl/manageUser/edit/${row.id}`),
      getIcon: () => <EditRoundedIcon />,
      route: '/accessControl/manageUser/edit',
    },
    {
      getLabel: (row) => (row.active ? 'Inativar' : 'Ativar'),
      action: (row) => {
        showSwal({
          title: `${row.active ? 'Inativar' : 'Ativar'} Usuário?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: (theme.palette.action as any).success,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            handleSituation(row);
          }
        });
      },
      getIcon: (row) =>
        row.active ? <CloseRoundedIcon /> : <CheckRoundedIcon />,
      route: '/accessControl/manageUser/edit',
    },
  ];

  const mutationSituation = useMutation({
    mutationFn: (row: any) => toggleUserStatus(row.id, !row.active),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    },
    onError: (error: any) => {
      console.error('Error toggling user status:', error);
    },
  });

  const handleSituation = (row: any) => {
    mutationSituation.mutate(row);
  };

  return (
    <Fragment>
      <AlphaContentBox>
        <Breadcrumb
          routeSegments={[
            { name: 'Controle de Acesso' },
            { name: 'Gerenciar Usuários' },
          ]}
        />
        <Grid container spacing={3} alignItems="center" mb={2} mt={1}>
          <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
            <NavLink to="/accessControl/manageUser/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddRoundedIcon />}
              >
                Cadastrar
              </Button>
            </NavLink>
          </Grid>
        </Grid>
        <Divider />
        <Box mt={3}>
          <CreateList
            tableName="user"
            service={fetchListUsers}
            menuItems={menus}
            columns={columns}
            exportOptions={{ fileName: 'Empresas' }}
            propsQuery={{
              staleTime: 1000 * 60 * 3, // 3 minutes
            }}
          />
        </Box>
      </AlphaContentBox>
      <UserInfoModal
        row={rowInfoModal}
        open={openModal}
        onClose={handleCloseModal}
      />
    </Fragment>
  );
};

export default UserListPage;

import { Button, Checkbox, Divider, Stack, Typography } from '@mui/material';
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';

const ColumnVisibilityToggle = ({ headers }: { headers: any }) => (
  <>
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={2}
      pt={2}
      pr={2}
      pl={2}
      pb={1}
      width="300px"
      maxWidth="300px"
    >
      <ViewColumnRoundedIcon />
      <Typography variant="h6">Exibir/Ocultar Colunas</Typography>
    </Stack>
    <Divider />
    <Stack
      pt={1}
      pr={1}
      pl={2}
      justifyContent={'center'}
      width="300px"
      maxWidth="300px"
    >
      {headers.map((header: any) => (
        <Stack
          key={header.id}
          direction={'row'}
          alignItems={'center'}
          spacing={1}
          pt={1}
          pr={1}
          justifyContent={'start'}
        >
          <Checkbox
            checked={header.column.getIsVisible()}
            onChange={() => {
              header.column.toggleVisibility();
            }}
          />
          <Typography>{(header.column.columnDef as any).header}</Typography>
        </Stack>
      ))}
    </Stack>
    <Stack justifyContent="center" p={2}>
      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          headers.forEach((header: any) => {
            !header.column.getIsVisible() && header.column.toggleVisibility();
          });
        }}
      >
        Exibir Todas
      </Button>
    </Stack>
  </>
);

export default ColumnVisibilityToggle;

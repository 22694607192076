import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

interface User {
  id: string;
  name: string;
  email: string;
}

interface InitProps {
  isAuthenticated: boolean;
  user: User | null;
  accessToken: string | null;
}

interface AuthState {
  user: User | null;
  isInitialised: boolean;
  isAuthenticated: boolean;
  accessToken?: string | null;
  init: (payload: InitProps) => void;
  login: (user: User, accessToken: string) => void;
  logout: () => void;
  register: (user: User, accessToken: string) => void;
}

const environment = import.meta.env.VITE_APP_ENV;

const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        isInitialised: false,
        isAuthenticated: false,
        accessToken: null,
        init: ({ isAuthenticated, user, accessToken }) =>
          set(
            {
              isAuthenticated,
              isInitialised: true,
              user,
              accessToken,
            },
            false,
            'init',
          ),
        login: (user, accessToken) =>
          set(
            {
              isAuthenticated: true,
              user,
              accessToken,
            },
            false,
            'login',
          ),
        logout: () =>
          set(
            {
              isAuthenticated: false,
              user: null,
              accessToken: null,
            },
            false,
            'logout',
          ),
        register: (user, accessToken) =>
          set(
            {
              isAuthenticated: true,
              user,
              accessToken,
            },
            false,
            'register',
          ),
      }),
      {
        name: 'auth', // localStorage
      },
    ),
    {
      store: 'auth', //devtools
      name: 'auth',
      enabled: environment == 'development', //devtools disable production
    },
  ),
);

export default useAuthStore;

import { AlphaContentBox } from '@core/components/Layout';
import { notify } from '@core/utils/notifyUtils';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import { useMutation } from '@tanstack/react-query';
import {
  changePasswordUser,
  UserChangePasswordParams,
} from '../services/userProfileService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@ilogix/hooks/useAuth';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 2,
};

const ProfileSecurity = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleLogout } = useAuth();

  const mutationUpdate = useMutation({
    mutationFn: (params: { values: UserChangePasswordParams }) =>
      changePasswordUser(params.values),
    onSuccess: () => {
      notify(
        'success',
        'Salvo com sucesso. É necessário logar novamente com sua nova senha',
      );
    },
    onError: (error: any) => {
      console.error('Error updating user:', error);
    },
  });

  const handleUpdate = (values: UserChangePasswordParams) => {
    mutationUpdate.mutate({ values });
    handleLogout();
    navigate('/session/signin');
  };

  const handleSavePassword = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      handleUpdate({ password, newPassword });
    }, 1000);
  };

  return (
    <AlphaContentBox>
      <Typography variant={isMobile ? 'h5' : 'h4'} mb={1}>
        Segurança
      </Typography>
      <Typography color="gray" sx={{ mb: 5 }}>
        Defina as configurações de segurança
      </Typography>
      <Divider />
      <Stack
        sx={{ mt: 5, mb: 5 }}
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
      >
        <Box>
          <Typography variant="h5">Senha</Typography>
          <Typography color="gray" fontSize={13}>
            Altere sua senha
          </Typography>
        </Box>
        <Stack
          sx={{ pl: isMobile ? 0 : 20 }}
          width={isMobile ? '100%' : '40%'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <TextField
            size="medium"
            fullWidth
            disabled
            type="password"
            variant="outlined"
            placeholder="Senha"
            value={'senhasenha'}
            InputProps={{
              endAdornment: (
                <Tooltip title="Clique no botão ao lado para alterar sua senha">
                  <LiveHelpRoundedIcon sx={{ color: 'gray' }} />
                </Tooltip>
              ),
            }}
          />
          <Button
            sx={{ width: '50%', ml: 2 }}
            variant="contained"
            onClick={handleOpen}
          >
            Alterar Senha
          </Button>
        </Stack>
      </Stack>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box p={1}>
            <Typography id="modal-password" variant="h4">
              Alterar Senha
            </Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <Stack
              sx={{ mt: 5, mb: 2 }}
              alignItems={'center'}
              direction={'row'}
              spacing={2}
            >
              <Typography fontSize={15} sx={{ width: '40%' }}>
                Senha Atual:
              </Typography>
              <TextField
                size="small"
                fullWidth
                type={showPassword ? 'password' : 'text'}
                variant="outlined"
                placeholder="Senha Atual"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack
              sx={{ mt: 2, mb: 5 }}
              alignItems={'center'}
              direction={'row'}
              spacing={2}
            >
              <Typography fontSize={15} sx={{ width: '40%' }}>
                Nova Senha:
              </Typography>
              <TextField
                size="small"
                fullWidth
                type={showNewPassword ? 'password' : 'text'}
                variant="outlined"
                placeholder="Nova Senha"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box>
                  <Button onClick={handleClose} color="error" variant="text">
                    Cancelar
                  </Button>
                </Box>
                <Button
                  onClick={handleSavePassword}
                  color="primary"
                  variant="contained"
                  sx={{ ml: 1 }}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </AlphaContentBox>
  );
};

export default ProfileSecurity;

import useAuthStore from '@core/store/authStore';

export interface Route {
  id?: number;
  name?: string;
  path?: string;
  element?: React.ComponentType | React.ElementType | any;
  routes?: Route[];
  children?: Route[];
  icon?: string | null;
}

export interface UserRoute {
  path: string;
  id: number;
  name: string;
  routes?: {
    module_id: number;
    children: {
      path: string;
    }[];
  }[];
}

const isRouteMatch = (staticPath: string, dynamicPath: string) => {
  const normalizedDynamicPath = dynamicPath
    .replace(/:\w+/g, '')
    .replace(/\/\*/g, '/')
    .replace(/\/$/, '');

  const normalizedStaticPath = staticPath
    .replace(/:\w+/g, '')
    .replace(/\/\*/g, '/')
    .replace(/\/$/, '');

  return normalizedStaticPath === normalizedDynamicPath;
};

export function routeCheck(
  paths: Route[],
  {
    isAuthenticated,
    userRoutes,
  }: { isAuthenticated: boolean; userRoutes: UserRoute[] },
  permitAll: boolean = false,
) {
  if (permitAll) {
    return paths;
  }
  const getAccessToken = () => useAuthStore.getState().accessToken;
  const accessToken = getAccessToken();

  if (!isAuthenticated || !accessToken) {
    return [];
  }

  const extractPaths = (routes: Route[]): string[] => {
    return routes.flatMap(
      (route) =>
        [
          route.path,
          ...(route.routes ? extractPaths(route.routes) : []),
          ...(route.children ? extractPaths(route.children) : []),
        ].filter(Boolean) as string[],
    );
  };
  const validPaths = extractPaths(userRoutes as Route[]);

  const authorizedPaths = paths.filter((item) =>
    validPaths.some((validPath) =>
      isRouteMatch(item.path as string, validPath),
    ),
  );

  return authorizedPaths;
}

import { useCreateRoutes } from '@ilogix/hooks/useRoute';
import {
  CompanyListPage,
  CompanyFormPage,
} from '@ilogix/views/accessControl/ManageCompany';
import {
  UserListPage,
  UserFormPage,
} from '@ilogix/views/accessControl/ManageUser';
import {
  GroupListPage,
  GroupFormPage,
  GroupManagePage,
} from '@ilogix/views/accessControl/ManageGroup';
import { Home } from '@ilogix/views/home';
import { ProfilePage } from '@ilogix/views/profile/ManageProfile';
import { LogListPage } from '@ilogix/views/accessControl/AccessLog';
import { UrlFormPage } from '@ilogix/views/accessControl/ManageUrl';
import { IntegrationDashboardPage } from './views/dashboard/Integrations';

export type Route = {
  name: string;
  path: string;
  element: React.ComponentType;
};

const accessControlRoutes: Route[] = [
  {
    name: 'Gerenciar Empresas',
    path: 'accessControl/manageCompany',
    element: CompanyListPage,
  },
  {
    name: 'Cadastrar Empresa',
    path: 'accessControl/manageCompany/add',
    element: CompanyFormPage,
  },
  {
    name: 'Editar Empresa',
    path: 'accessControl/manageCompany/edit/:id',
    element: CompanyFormPage,
  },
  {
    name: 'Gerenciar Usuários',
    path: 'accessControl/manageUser',
    element: UserListPage,
  },
  {
    name: 'Cadastrar Usuário',
    path: 'accessControl/manageUser/add',
    element: UserFormPage,
  },
  {
    name: 'Editar Usuário',
    path: 'accessControl/manageUser/edit/:id',
    element: UserFormPage,
  },
  {
    name: 'Gerenciar Grupos',
    path: 'accessControl/manageGroup',
    element: GroupListPage,
  },
  {
    name: 'Cadastrar Grupo',
    path: 'accessControl/manageGroup/add',
    element: GroupFormPage,
  },
  {
    name: 'Editar Grupo',
    path: 'accessControl/manageGroup/edit/:id',
    element: GroupFormPage,
  },
  {
    name: 'Gerenciar',
    path: 'accessControl/manageGroup/manage/:id',
    element: GroupManagePage,
  },
  {
    name: 'Log de Acesso',
    path: 'accessControl/accessLog',
    element: LogListPage,
  },
  {
    name: 'Gerenciar URL',
    path: 'accessControl/manageUrl',
    element: UrlFormPage,
  },
];

const defaultRoutes: Route[] = [{ name: 'Home', path: 'home', element: Home }];
const dashboardRoutes: Route[] = [
  {
    name: 'Integrações',
    path: 'dashboard/integration',
    element: IntegrationDashboardPage,
  },
];
const profileRoutes: Route[] = [
  {
    name: 'Gerenciar Perfil',
    path: 'profile/manageProfile',
    element: ProfilePage,
  },
];

const useRoutesIlogix = () => {
  const allRoutes = [
    ...accessControlRoutes,
    ...defaultRoutes,
    ...profileRoutes,
    ...dashboardRoutes,
  ];

  return useCreateRoutes(allRoutes as any);
};

export default useRoutesIlogix;

import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { motion } from 'framer-motion';

interface ModalProps {
  row?: {
    name: string;
    email: string;
    group: {
      name: string;
    };
  };
  open: boolean;
  onClose: () => void;
}

const styleMotion = {
  position: 'absolute',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  outline: 'none',
};
const MotionBox = motion(Box);

const UserInfoModal = ({ row, open, onClose }: ModalProps) => {
  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={onClose}
    >
      <MotionBox
        sx={styleMotion}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        <Box
          pb={2}
          pl={2}
          pt={2}
          pr={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h5">Informações do Usuário</Typography>
          <CloseRoundedIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
        </Box>
        <Divider />
        <Box p={3} mb={2}>
          {row ? (
            <>
              <Typography variant="body1" pb={1} pt={1}>
                <strong>Nome:</strong> {row.name}
              </Typography>
              <Typography variant="body1" pb={1} pt={1}>
                <strong>Email:</strong> {row.email}
              </Typography>
              <Typography variant="body1" pt={1}>
                <strong>Grupo:</strong> {row.group.name}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">Nenhum dado encontrado</Typography>
          )}
        </Box>
        <Divider />
        <Box
          p={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          <Button onClick={onClose} variant="contained" color="primary">
            Fechar
          </Button>
        </Box>
      </MotionBox>
    </Modal>
  );
};

export default UserInfoModal;

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  Grid,
  Box,
  TextField,
  Typography,
  Card,
  Autocomplete,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { BreadcrumbForm } from '@core/components/LayoutTheme';
import * as z from 'zod';
import { AlphaContentBox } from '@core/components/Layout';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  editUser,
  fetchUser,
  registerUser,
  UserParams,
} from '../services/userService';
import { HeaderForm } from '@core/components/Form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  fetchAllGroups,
  GroupParams,
} from '../../ManageGroup/services/groupService';
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';

interface Params extends Record<string, string | undefined> {
  id?: string;
}

const companySchema = z.object({
  name: z.string({ required_error: 'Nome é obrigatório' }),
  email: z.string({ required_error: 'E-mail é obrigatório' }).email({
    message: 'E-mail inválido',
  }),
  group_id: z.number({ required_error: 'Grupo é obrigatório' }),
});

const UserFormPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams<Params>();
  const isEditing = !!id;

  const { data: groups } = useQuery<GroupParams[]>({
    queryKey: ['fetchAllGroups'],
    queryFn: fetchAllGroups,
  });

  const initialValues = {
    name: '',
    email: '',
    group_id: '',
  };

  const mutationCreate = useMutation({
    mutationFn: (params: { values: UserParams }) => registerUser(params.values),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    },
    onError: (error: any) => {
      console.error('Error create user:', error);
    },
  });

  const handleCreate = async (values: UserParams) => {
    try {
      mutationCreate.mutate({ values });

      navigate(-1);
    } catch (error) {
      console.error('Failed to register user:', error);
    }
  };

  const mutationUpdate = useMutation({
    mutationFn: (params: { id: string; values: UserParams }) =>
      editUser(params.id, params.values),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    },
    onError: (error: any) => {
      console.error('Error updating user:', error);
    },
  });

  const handleUpdate = (id: string, values: UserParams) => {
    mutationUpdate.mutate({ id, values });

    navigate(-1);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(companySchema),
    onSubmit: () => {
      if (isEditing) {
        handleUpdate(id, formik.values);
      } else {
        handleCreate(formik.values);
      }
    },
  });

  const { data: user } = useQuery<UserParams>({
    queryKey: ['fetchUser', id],
    queryFn: () => {
      if (id !== undefined) {
        return fetchUser(id);
      }
      return Promise.reject('ID is undefined');
    },
    enabled: isEditing && id !== undefined,
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        name: user.name || '',
        email: user.email || '',
        group_id: user.group_id || '',
      });
    }
  }, [user]);

  return (
    <AlphaContentBox>
      <BreadcrumbForm
        routeSegments={[
          {
            name: 'Gerenciar Usuários',
            path: '/accessControl/manageUser',
          },
        ]}
      />
      <Grid container spacing={3} alignItems="center" p={6}>
        <Grid item xs={12}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            {isEditing ? (
              <HeaderForm
                title={formik.values?.name}
                subtitle="Editar Usuário"
              />
            ) : (
              <HeaderForm title="Usuário" subtitle="Cadastrar Usuário" />
            )}
            <Card elevation={2} sx={{ borderRadius: '10px', padding: '5px' }}>
              <Typography p={2} variant="h6" gutterBottom>
                Informações Básicas
              </Typography>
              <Grid container spacing={2} pl={2} pr={2} pb={2}>
                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Nome"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="E-mail"
                    disabled={isEditing}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputProps={{
                      endAdornment: isEditing ? (
                        <Tooltip title="Não é possível alterar o email">
                          <LiveHelpRoundedIcon sx={{ color: 'gray' }} />
                        </Tooltip>
                      ) : (
                        <></>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    fullWidth
                    id="group_id"
                    options={groups || []}
                    getOptionLabel={(option: GroupParams) => option.name || ''}
                    value={
                      groups?.find(
                        (group: GroupParams) =>
                          group.id === formik.values.group_id,
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.group_id &&
                          Boolean(formik.errors.group_id)
                        }
                        helperText={
                          formik.touched.group_id && formik.errors.group_id
                        }
                        label="Grupo"
                      />
                    )}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('group_id', newValue?.id || '');
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid item p={2} xs={12} textAlign="right">
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={() => navigate(-1)}
                  sx={{ mr: 2 }}
                >
                  Voltar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    mutationCreate.isPending || mutationUpdate.isPending
                  }
                >
                  {mutationCreate.isPending || mutationUpdate.isPending ? (
                    <CircularProgress size={24} />
                  ) : isEditing ? (
                    'Salvar Alterações'
                  ) : (
                    'Cadastrar'
                  )}
                </Button>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </AlphaContentBox>
  );
};

export default UserFormPage;

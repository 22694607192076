import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Drawer,
  Popover,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSettings } from '@ilogix/hooks/useSettings';
import { sideNavWidth } from '@core/utils/constantUtils';
import { notify } from '@core/utils/notifyUtils';
import AlphaDark from '@ilogix/assets/images/alpha-dark.jpg';
import AlphaLight from '@ilogix/assets/images/alpha-light.jpg';
import FilterLine from '@ilogix/assets/images/filtro-line.jpg';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { useEffect, useState } from 'react';
import { AlphaContentBox } from '@core/components/Layout';
import useTableStore from '@core/store/tableStore';

const RenderDrawer = ({ panelOpen, setPanelOpen }: any) => {
  const theme = useTheme();

  return (
    <Drawer
      sx={{ width: '100px' }}
      variant="temporary"
      anchor={'right'}
      open={panelOpen}
      onClose={() => setPanelOpen(false)}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box sx={{ width: sideNavWidth }}>
        <Stack direction="row" justifyItems="center" spacing={2} p={2}>
          <FilterListRoundedIcon />
          <Typography variant="h6">Filtros</Typography>
        </Stack>
        <Divider />
        <Box p={2}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Pesquisar por nome"
            variant="outlined"
            InputProps={{
              sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor:
                    theme.palette.mode === 'light' ? '#d3d3d3' : '#737373',
                },
              },
            }}
          />
        </Box>
        <Stack direction="row" justifyContent="end" p={2}>
          <Button variant="contained" fullWidth>
            Limpar
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

const RenderPopover = ({ anchor, popoverOpen, setPopoverOpen }: any) => {
  const theme = useTheme();

  return (
    <Popover
      open={popoverOpen}
      anchorEl={anchor}
      onClose={() => setPopoverOpen(false)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={2}
        pt={2}
        pr={2}
        pl={2}
        pb={1}
        width="300px"
        maxWidth="300px"
      >
        <FilterAltRoundedIcon />
        <Typography variant="h6">Filtros</Typography>
      </Stack>
      <Divider />
      <Stack
        pt={1}
        pr={1}
        pl={2}
        justifyContent={'center'}
        width="300px"
        maxWidth="300px"
      >
        <TextField
          fullWidth
          id="outlined-basic"
          label="Pesquisar por nome"
          variant="outlined"
          InputProps={{
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor:
                  theme.palette.mode === 'light' ? '#d3d3d3' : '#737373',
              },
              marginBottom: '10px',
            },
          }}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          label="Pesquisar por email"
          variant="outlined"
          InputProps={{
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor:
                  theme.palette.mode === 'light' ? '#d3d3d3' : '#737373',
              },
              marginBottom: '10px',
            },
          }}
        />
      </Stack>
      <Stack justifyContent="center" p={2}>
        <Button variant="contained" fullWidth>
          Limpar
        </Button>
      </Stack>
    </Popover>
  );
};

const RenderLine = ({ anchor2, lineOpen, setLineOpen }: any) => {
  return (
    <Popover
      open={lineOpen}
      anchorEl={anchor2}
      onClose={() => setLineOpen(false)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Stack
        pt={1}
        pr={1}
        pl={2}
        justifyContent={'center'}
        width="700px"
        maxWidth="700px"
      >
        <img
          src={FilterLine}
          alt="filtro linha"
          style={{
            width: '100%',
          }}
        />
      </Stack>
    </Popover>
  );
};

const ProfileAppearance = () => {
  const theme = useTheme();
  const { updateSettings } = useSettings();
  const [panelOpen, setPanelOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [lineOpen, setLineOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme') ?? 'alphaLight';
    setSelectedTheme(storedTheme);
    const selectedTheme = useTableStore.getState().filterType;
    setSelectedFilter(selectedTheme);
  }, []);

  const updateTheme = () => {
    const activeTheme =
      selectedTheme == 'alphaLight' ? 'alphaBlueLight' : 'alphaBlueDark2';
    localStorage.setItem('theme', selectedTheme);
    updateSettings({
      layout1Settings: {
        leftSidebar: {
          theme: selectedTheme,
        },
        topbar: {
          theme:
            selectedTheme == 'alphaLight' ? 'alphaBlueDark' : 'alphaPurpleDark',
        },
      },
      activeTheme: activeTheme,
      secondarySidebar: {
        theme: selectedTheme,
      },
      footer: {
        theme: selectedTheme,
      },
    });
  };

  const updateFilterMode = () => {
    useTableStore.setState({ filterType: selectedFilter });
  };

  const handleThemeClick = (theme: any) => {
    setSelectedTheme(theme);
  };

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
  };

  const handleFilterView = (
    filter: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setSelectedFilter(filter);
    switch (filter) {
      case 'drawer':
        setPanelOpen(true);
        break;
      case 'popover':
        setAnchor(event.currentTarget as any);
        setPopoverOpen(true);
        break;
      case 'line':
        setAnchor2(event.currentTarget as any);
        setLineOpen(true);
        break;
      default:
        setAnchor(null);
        setAnchor2(null);
        setPopoverOpen(false);
        setPanelOpen(false);
        setLineOpen(false);
        break;
    }
  };

  const handleSave = () => {
    setIsLoading(true);
    setTimeout(() => {
      updateTheme();
      updateFilterMode();
      setIsLoading(false);
      notify('success', 'Salvo com sucesso');
    }, 1000);
  };

  return (
    <AlphaContentBox>
      <Typography variant={isMobile ? 'h5' : 'h4'} mb={1}>
        Aparência
      </Typography>
      <Typography color="gray" sx={{ mb: 5 }}>
        Defina suas principais configurações de aparência
      </Typography>
      <Divider />
      <Stack
        sx={{ mt: 5, mb: 5 }}
        direction={isMobile ? 'column' : 'row'}
        justifyContent={'space-between'}
        spacing={2}
      >
        <Box sx={{ width: isMobile ? '100%' : '40%' }}>
          <Typography variant={isMobile ? 'h5' : 'h4'}>Tema</Typography>
          <Typography color="gray">
            Customize a cor principal do sistema
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <ButtonBase
                onClick={() => handleThemeClick('alphaDark')}
                sx={{
                  position: 'relative',
                  width: 300,
                  height: 150,
                  borderRadius: 2,
                  overflow: 'hidden',
                  '&:hover': {
                    borderColor: 'primary.main',
                  },
                  border: `3px solid ${
                    selectedTheme === 'alphaDark'
                      ? theme.palette.primary.main
                      : 'transparent'
                  }`,
                }}
              >
                <img
                  src={AlphaDark}
                  alt="Tema Escuro"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </ButtonBase>
              {selectedTheme === 'alphaDark' && (
                <CheckCircleRoundedIcon
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: -10,
                    right: -8,
                    zIndex: 2,
                    backgroundColor: 'white',
                    borderRadius: '80%',
                  }}
                />
              )}
            </Box>
            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <ButtonBase
                onClick={() => handleThemeClick('alphaLight')}
                sx={{
                  position: 'relative',
                  width: 300,
                  height: 150,
                  borderRadius: 2,
                  overflow: 'hidden',
                  '&:hover': {
                    borderColor: 'primary.main',
                  },
                  border: `3px solid ${
                    selectedTheme === 'alphaLight'
                      ? theme.palette.primary.main
                      : 'transparent'
                  }`,
                }}
              >
                <img
                  src={AlphaLight}
                  alt="Tema Claro"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </ButtonBase>
              {selectedTheme === 'alphaLight' && (
                <CheckCircleRoundedIcon
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: -10,
                    right: -8,
                    zIndex: 2,
                    backgroundColor: 'white',
                    borderRadius: '80%',
                  }}
                />
              )}
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Divider />
      <Stack sx={{ mt: 5 }} direction={isMobile ? 'column' : 'row'} spacing={2}>
        <Box sx={{ width: isMobile ? '100%' : '40%' }}>
          <Typography variant="h4">Filtro</Typography>
          <Typography color="gray">Defina o estilo dos filtros</Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Stack
            direction={'row'}
            display={isMobile ? 'inline' : ''}
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 100,
                  height: 100,
                  borderRadius: 2,
                  overflow: 'hidden',
                  border: `${
                    selectedFilter === 'drawer' ? '3px' : '1px'
                  } solid ${
                    selectedFilter === 'drawer'
                      ? theme.palette.primary.main
                      : theme.palette.primary.main
                  }`,
                }}
              >
                <ButtonBase
                  onClick={() => handleFilterClick('drawer')}
                  sx={{
                    width: '100%',
                    height: '50%',
                    '&:hover': {
                      borderColor: 'primary.main',
                    },
                  }}
                >
                  Estilo 1
                </ButtonBase>

                <Button
                  onClick={(e) => handleFilterView('drawer', e)}
                  variant="outlined"
                  sx={{
                    width: '100%',
                    height: '50%',
                    borderRadius: 0,
                  }}
                >
                  Visualizar
                </Button>
              </Box>

              {selectedFilter === 'drawer' && (
                <CheckCircleRoundedIcon
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: -10,
                    right: -8,
                    zIndex: 2,
                    backgroundColor: 'white',
                    borderRadius: '80%',
                  }}
                />
              )}
            </Box>

            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 100,
                  height: 100,
                  borderRadius: 2,
                  overflow: 'hidden',
                  border: `${selectedFilter === 'line' ? '3px' : '1px'} solid ${
                    selectedFilter === 'line'
                      ? theme.palette.primary.main
                      : theme.palette.primary.main
                  }`,
                }}
              >
                <ButtonBase
                  onClick={() => handleFilterClick('line')}
                  sx={{
                    width: '100%',
                    height: '50%',
                    '&:hover': {
                      borderColor: 'primary.main',
                    },
                  }}
                >
                  Estilo 2
                </ButtonBase>

                <Button
                  onClick={(e) => handleFilterView('line', e)}
                  variant="outlined"
                  sx={{
                    width: '100%',
                    height: '50%',
                    borderRadius: 0,
                  }}
                >
                  Visualizar
                </Button>
              </Box>

              {selectedFilter === 'line' && (
                <CheckCircleRoundedIcon
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: -10,
                    right: -8,
                    zIndex: 2,
                    backgroundColor: 'white',
                    borderRadius: '80%',
                  }}
                />
              )}
            </Box>
            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 100,
                  height: 100,
                  borderRadius: 2,
                  overflow: 'hidden',
                  border: `${
                    selectedFilter === 'popover' ? '3px' : '1px'
                  } solid ${
                    selectedFilter === 'popover'
                      ? theme.palette.primary.main
                      : theme.palette.primary.main
                  }`,
                }}
              >
                <ButtonBase
                  onClick={() => handleFilterClick('popover')}
                  sx={{
                    width: '100%',
                    height: '50%',
                    '&:hover': {
                      borderColor: 'primary.main',
                    },
                  }}
                >
                  Estilo 3
                </ButtonBase>

                <Button
                  onClick={(e) => handleFilterView('popover', e)}
                  variant="outlined"
                  sx={{
                    width: '100%',
                    height: '50%',
                    borderRadius: 0,
                  }}
                >
                  Visualizar
                </Button>
              </Box>

              {selectedFilter === 'popover' && (
                <CheckCircleRoundedIcon
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: -10,
                    right: -8,
                    zIndex: 2,
                    backgroundColor: 'white',
                    borderRadius: '80%',
                  }}
                />
              )}
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Stack pt={5}>
        <Divider />
      </Stack>
      <Stack>
        <Box display={'flex'} justifyContent={'flex-end'}>
          {/* <Button color="inherit" variant="outlined" sx={{ mt: 5 }}>
            Cancelar
          </Button> */}
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            sx={{ mt: 5, ml: 1 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Salvar Alterações'}
          </Button>
        </Box>
      </Stack>
      {selectedFilter === 'drawer' && (
        <RenderDrawer panelOpen={panelOpen} setPanelOpen={setPanelOpen} />
      )}
      {selectedFilter === 'popover' && (
        <RenderPopover
          anchor={anchor}
          popoverOpen={popoverOpen}
          setPopoverOpen={setPopoverOpen}
        />
      )}
      {selectedFilter === 'line' && (
        <RenderLine
          anchor2={anchor2}
          lineOpen={lineOpen}
          setLineOpen={setLineOpen}
        />
      )}
    </AlphaContentBox>
  );
};

export default ProfileAppearance;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { AlphaContentBox } from '@core/components/Layout';
import { Breadcrumb } from '@core/components/LayoutTheme';

const UrlFormPage: React.FC = () => {
  return (
    <AlphaContentBox>
      <Breadcrumb
        routeSegments={[
          { name: 'Controle de Acesso' },
          { name: 'Gerenciar URL' },
        ]}
      />
      <Box mt={3}>
        <Typography variant="h4">Gerenciar URL</Typography>
      </Box>
    </AlphaContentBox>
  );
};

export default UrlFormPage;

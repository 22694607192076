import { decrypt } from '@core/utils/cryptoUtils';
import { UserRoute } from '@core/data/roles';
import { useMemo } from 'react';

export const useUserRoutes = (): UserRoute[] => {
  const encryptedRoutes = localStorage.getItem('routes');

  const parsedRoutes = useMemo(() => {
    if (encryptedRoutes) {
      const decryptedRoutes = decrypt(encryptedRoutes);
      return JSON.parse(decryptedRoutes);
    }
    return [];
  }, [encryptedRoutes]);

  return parsedRoutes;
};

export const useUserRoutesChildren = (): UserRoute[] => {
  const userRoutes = useUserRoutes();
  const childrenRoutes = useMemo(() => {
    const allChildren: UserRoute[] = [];

    userRoutes.forEach((route) => {
      route.routes?.forEach((subRoute: { children: any[]; module_id: any }) => {
        subRoute.children?.forEach((child: { path: any }) => {
          allChildren.push({
            path: child.path,
            id: subRoute.module_id,
            name: route.name,
          });
        });
      });
    });

    return allChildren;
  }, [userRoutes]);

  return childrenRoutes;
};

export const useCreateRoutes = (
  routesArray: { name: string; path: string; element: React.ComponentType }[][],
) => {
  return useMemo(() => {
    return routesArray.flat().map((route) => ({
      ...route,
      path: `/${route.path}`,
    }));
  }, [routesArray]);
};

// src/api/request.ts
import { AxiosRequestConfig } from 'axios';
import { api } from './api';
import { notify } from '@core/utils/notifyUtils';

interface RequestOptions<T> {
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  url: string;
  data?: T;
  config?: AxiosRequestConfig;
}

/**
 * Realiza uma requisição HTTP utilizando as opções fornecidas e retorna os dados da resposta.
 * Obs: Adapter para requisições de data table, permitindo a configuração do método, URL, dados e opções adicionais.
 *
 * @param {RequestOptions<T>} options - As opções para a requisição.
 * @param {string} options.method - O método HTTP a ser usado para a requisição.
 * @param {string} options.url - A URL para onde a requisição será enviada.
 * @param {any} [options.data] - Os dados a serem enviados com a requisição.
 * @param {AxiosRequestConfig} [options.config] - Opções adicionais de configuração para a requisição.
 *
 * @returns {Promise<T>} Uma promessa que resolve com os dados da resposta.
 *
 * @throws {Error} Se ocorrer um erro durante a requisição.
 */
export const request = async <T>({
  method,
  url,
  data,
  config,
}: RequestOptions<T>): Promise<T> => {
  try {
    const response = await api({
      method,
      url,
      data,
      ...config,
    });
    return response.data.response as T;
  } catch (error) {
    console.error(`Error in ${method.toUpperCase()} request to ${url}:`, error);
    throw error;
  }
};

/**
 * Obtém dados do endpoint especificado com os parâmetros fornecidos.
 * Obs: Esta função adiciona os parâmetros necessários para a chamada da API, incluindo offset, tamanho, ordenação, filtros e filtro global, para que o retorno seja um data table.
 *
 * @param {RequestTableOptions} param - Um objeto contendo o endpoint e os parâmetros para a recuperação dos dados.
 * @param {string} param.endpoint - O URL base do endpoint de onde os dados serão recuperados.
 *
 * @returns {Promise<any>} - Retorna uma promessa que resolve os dados obtidos da API.
 *
 * @throws {Error} - Lança um erro caso ocorra uma falha na chamada da API.
 */
export const requestTable = async ({ endpoint, params }: any): Promise<any> => {
  try {
    const { offset, size, sorting, filters, globalFilter } = params;

    const url = `${endpoint}/${offset}/${size}/${JSON.stringify(sorting)}/${JSON.stringify(filters)}/${JSON.stringify(globalFilter)}`;

    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    notify('errorTable', 'Erro ao carregar os dados');
    throw error;
  }
};

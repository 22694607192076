import { HeaderForm } from '@core/components/Form';
import { AlphaContentBox } from '@core/components/Layout';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import ProfileAppearance from '../components/ProfileAppearance';
import ProfileGeneral from '../components/ProfileGeneral';
import ProfileSecurity from '../components/ProfileSecurity';
import ProfileNotification from '../components/ProfileNotification';
import { Breadcrumb } from '@core/components/LayoutTheme';

const ProfilePage = () => {
  const [value, setValue] = useState('1');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <AlphaContentBox>
      <Breadcrumb
        routeSegments={[
          { name: 'Home', path: '/home' },
          { name: 'Gerenciar Perfil', path: '/profile/manageProfile' },
        ]}
      />

      <Stack p={isMobile ? 1 : 2}>
        <Stack pt={4} pl={4} pb={2}>
          <HeaderForm title="Perfil" subtitle="Configurações" />
        </Stack>
        <TabContext value={value}>
          <Box pl={5} pr={5}>
            <TabList
              onChange={handleChange}
              variant={isMobile ? 'scrollable' : 'standard'}
              scrollButtons="auto"
              TabIndicatorProps={{ sx: { border: '0px' } }}
            >
              <Tab label="Geral" value="1" />
              <Tab label="Aparência" value="2" />
              <Tab label="Segurança" value="3" />
              <Tab label="Notificações" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ pt: 3 }}>
            <ProfileGeneral />
          </TabPanel>
          <TabPanel value="2" sx={{ pt: 3 }}>
            <ProfileAppearance />
          </TabPanel>
          <TabPanel value="3" sx={{ pt: 3 }}>
            <ProfileSecurity />
          </TabPanel>
          <TabPanel value="4" sx={{ pt: 3 }}>
            <ProfileNotification />
          </TabPanel>
        </TabContext>
      </Stack>
    </AlphaContentBox>
  );
};

export default ProfilePage;

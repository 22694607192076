import { api } from '@core/data/api';
import { encrypt } from '@core/utils/cryptoUtils';

export const fetchUserRoutes = async (): Promise<any> => {
  try {
    const response = await api.get('/accessControl/routes');
    const routesData = response.data.response;

    const encryptedRoutes = encrypt(JSON.stringify(routesData));

    localStorage.setItem('routes', encryptedRoutes || '');

    return routesData;
  } catch (error) {
    throw error;
  }
};

import { AlphaContentBox } from '@core/components/Layout';
import useAuthStore from '@core/store/authStore';
import { notify } from '@core/utils/notifyUtils';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';

const ProfileGeneral = () => {
  const user = useAuthStore((state) => state.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      notify('success', 'Salvo com sucesso');
    }, 1000);
  };

  return (
    <AlphaContentBox>
      <Typography variant={isMobile ? 'h5' : 'h4'} mb={1}>
        Geral
      </Typography>
      <Typography color="gray" sx={{ mb: 5 }}>
        Defina as configurações gerais do perfil
      </Typography>
      <Divider />
      <Stack
        sx={{ mt: 5, mb: 5 }}
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
      >
        <Box>
          <Typography variant="h5">Nome</Typography>
          <Typography color="gray" fontSize={13}>
            Nome do Perfil
          </Typography>
        </Box>
        <Box
          sx={{
            pl: isMobile ? 0 : 20,
            width: isMobile ? '100%' : '60%',
          }}
        >
          <TextField
            size="medium"
            fullWidth
            variant="outlined"
            placeholder="Nome do Perfil"
            value={user?.name || ''}
          />
        </Box>
      </Stack>
      <Divider />
      <Stack sx={{ mt: 5 }} direction={isMobile ? 'column' : 'row'} spacing={2}>
        <Box>
          <Typography variant="h5">Email</Typography>
          <Typography color="gray" fontSize={13}>
            Email do Perfil
          </Typography>
        </Box>
        <Box
          sx={{
            pl: isMobile ? 0 : 20,
            width: isMobile ? '100%' : '60%',
          }}
        >
          <TextField
            size="medium"
            fullWidth
            disabled
            variant="outlined"
            placeholder="Email do Perfil"
            value={user?.email || ''}
            InputProps={{
              endAdornment: (
                <Tooltip title="Não é possível alterar o email">
                  <LiveHelpRoundedIcon sx={{ color: 'gray' }} />
                </Tooltip>
              ),
            }}
          />
        </Box>
      </Stack>
      <Stack>
        <Box
          display={'flex'}
          justifyContent={isMobile ? 'center' : 'flex-end'}
          sx={{ mt: 5 }}
        >
          <Button color="inherit" variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            sx={{ ml: 1 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Salvar Alterações'}
          </Button>
        </Box>
      </Stack>
    </AlphaContentBox>
  );
};

export default ProfileGeneral;

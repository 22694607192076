import React, { useMemo } from 'react';
import { Box, Button, Divider, Grid } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CreateList, {
  MenuItemProps,
} from '@core/components/DataTable/CreateList';
import { NavLink, useNavigate } from 'react-router-dom';
import { AlphaContentBox } from '@core/components/Layout';
import { Breadcrumb } from '@core/components/LayoutTheme';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  fetchListCompany,
  fetchAllDirectors,
  fetchAllMatrices,
} from '../services/companyService';
import { useQuery } from '@tanstack/react-query';

const CompanyListPage: React.FC = () => {
  const navigate = useNavigate();

  const { data: directors } = useQuery({
    queryKey: ['directors'],
    queryFn: fetchAllDirectors,
    select: (data) =>
      data.map((director) => ({
        value: director.id,
        label: director.name,
      })),
    staleTime: 1000 * 60 * 3, // 3 minutes
  });

  const { data: matrices } = useQuery({
    queryKey: ['matrices'],
    queryFn: fetchAllMatrices,
    select: (data) =>
      data.map((matriz) => ({
        value: matriz.id,
        label: matriz.name,
      })),
    staleTime: 1000 * 60 * 3, // 3 minutes
  });

  const columns = useMemo(
    () => [
      {
        header: 'Informações Básicas',
        columns: [
          {
            id: 'name',
            header: 'Nome',
            accessorKey: 'name',
            filterVariant: 'text',
          },
          {
            id: 'telephone',
            header: 'Telefone',
            accessorKey: 'telephone',
            filterVariant: 'text',
          },
          {
            id: 'director_contact_id',
            header: 'Diretor',
            accessorKey: 'director_contact.name',
            filterVariant: 'select',
            selectOptions: directors || [],
          },
          {
            id: 'matrix_id',
            header: 'Matriz',
            accessorKey: 'matrix.name',
            filterVariant: 'select',
            selectOptions: matrices || [],
          },
        ],
      },
      {
        header: 'Localização',
        columns: [
          {
            id: 'address',
            header: 'Endereço',
            accessorKey: 'address',
            filterVariant: 'text',
          },
          {
            id: 'city',
            header: 'Cidade',
            accessorKey: 'city.name',
            filterVariant: 'text',
          },
          {
            id: 'neighborhood',
            header: 'Bairro',
            accessorKey: 'neighborhood.name',
            filterVariant: 'text',
          },
          {
            id: 'cep',
            header: 'CEP',
            accessorKey: 'cep',
            filterVariant: 'text',
          },
        ],
      },
    ],
    [directors, matrices],
  );

  const menus: MenuItemProps[] = [
    {
      getLabel: () => 'Editar',
      action: (row) => navigate(`/accessControl/manageCompany/edit/${row.id}`),
      getIcon: () => <EditRoundedIcon />,
      route: '/accessControl/manageCompany/edit',
    },
  ];

  return (
    <AlphaContentBox>
      <Breadcrumb
        routeSegments={[
          { name: 'Controle de Acesso' },
          { name: 'Gerenciar Empresas' },
        ]}
      />
      <Grid container spacing={3} alignItems="center" mb={2} mt={1}>
        <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
          <NavLink to="/accessControl/manageCompany/add">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddRoundedIcon />}
            >
              Cadastrar
            </Button>
          </NavLink>
        </Grid>
      </Grid>
      <Divider />
      <Box mt={3}>
        <CreateList
          tableName="company"
          service={fetchListCompany}
          menuItems={menus}
          columns={columns}
          exportOptions={{ fileName: 'Empresas' }}
          propsQuery={{
            staleTime: 1000 * 60 * 3, // 3 minutes
          }}
        />
      </Box>
    </AlphaContentBox>
  );
};

export default CompanyListPage;

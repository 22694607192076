import axios from 'axios';
import useAuthStore from '@core/store/authStore';
import { notify } from '@core/utils/notifyUtils';

const getAccessToken = () => useAuthStore.getState().accessToken;
const BASE_URL = import.meta.env.VITE_BASE_URL;
const notificationDebounce = new Set();

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();

    if (
      config.url !== 'auth/login' &&
      config.url !== 'auth/logout' &&
      config.url !== 'auth/activateAccount' &&
      accessToken
    ) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return Promise.resolve(config);
  },
  (error) => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    if (
      response.config.method !== 'get' &&
      response.config.url !== 'auth/login' &&
      response.config.url !== 'auth/verify-activate-account-token'
    ) {
      const message =
        response.data?.response || 'Operação realizada com sucesso';

      if (!notificationDebounce.has(message)) {
        notificationDebounce.add(message);
        notify('success', message);

        setTimeout(() => {
          notificationDebounce.delete(message);
        }, 3000);
      }
    }

    return response;
  },
  async (error) => {
    if (error.response) {
      const originalConfig = error.config;
      const { status, data } = error.response;

      if (
        originalConfig.url !== 'auth/login' &&
        originalConfig.url !== 'auth/logout' &&
        originalConfig.url !== 'auth/activateAccount' &&
        status === 401
      ) {
        useAuthStore.getState().logout();
        notify(
          'error',
          'Sua sessaão expirou. Por favor, realize o login novamente',
        );
        window.location.replace('/session/signin');
      }

      if (
        status === 400 &&
        originalConfig.url !== 'auth/verify-activate-account-token'
      ) {
        const errorMessage = data?.response || 'Ocorreu um erro';
        if (!notificationDebounce.has(errorMessage)) {
          notificationDebounce.add(errorMessage);
          notify('error', errorMessage);

          setTimeout(() => {
            notificationDebounce.delete(errorMessage);
          }, 3000);
        }
      }

      return Promise.reject(error);
    } else {
      notify('error', 'Erro de rede. Verifique sua conexão.');
      return Promise.reject(error);
    }
  },
);

export { api };

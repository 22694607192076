import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import {
  MRT_Row,
  MRT_TableContainer,
  MRT_TableOptions,
  MRT_TablePagination,
  useMaterialReactTable,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useUserRoutesChildren } from '@ilogix/hooks/useRoute';
import { DensityMedium, ViewCompact } from '@mui/icons-material';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { jsPDF } from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { sideNavWidth } from '@core/utils/constantUtils';
import { motion } from 'framer-motion';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { fetchTableRows } from '@core/services/tableService';
import useTableStore from '@core/store/tableStore';
import ColumnVisibilityToggle from './ColumnVisibilityToggle';

export interface DataTableParams {
  offset: number;
  size: number;
  sorting: Object[];
  filters: Object[];
  globalFilter: string;
}
type Props = {
  columnsToDisplay?: Column[];
} & MRT_TableOptions<any>;

interface ApiResponse {
  data: Array<any>;
  rowCount?: number;
}

interface TotalRowResponse {
  totalRow?: number;
}

export interface CreateListProps {
  tableName: string;
  propsQuery?: any;
  service: (args: any) => Promise<any>;
  props?: Props;
  propsInitial?: Props;
  menuItems?: MenuItemProps[];
  columns: any;
  customFilters?: any;
  exportOptions?: ExportOptions;
  onRefresh?: () => void;
}

interface ExportOptions {
  csv?: boolean;
  pdf?: boolean;
  fileName: string;
}

export interface MenuItemProps {
  getLabel: (row: any) => string;
  action: (row: any) => void;
  getIcon: (row: any) => JSX.Element;
  route?: string;
}

export interface Column {
  name: string;
  label: string;
  columns?: Column[];
}
// End of types

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const RenderFilters = lazy(() => import('./RenderFilters'));

const renderFiltersComponent = (
  type: 'drawer' | 'popover' | 'line',
  table: any,
  customFilters: any,
  setColumnFilters: any,
) => {
  if (type === 'line' || type === 'drawer' || type === 'popover') {
    return (
      <Suspense fallback={<CircularProgress />}>
        <RenderFilters
          table={table}
          customFilters={customFilters}
          setColumnFilters={setColumnFilters}
          headerFilter={true}
        />
      </Suspense>
    );
  }
  return null;
};

// Component
const CreateList: React.FC<CreateListProps> = ({
  tableName,
  propsQuery = {},
  service,
  menuItems,
  props,
  propsInitial,
  columns,
  customFilters,
  exportOptions = { csv: true, pdf: true, fileName: 'table' },
}) => {
  const { csv = true, pdf = true } = exportOptions;
  const [limit, setLimit] = useState(400);
  const [savedPagination, setSavedPagination] = useState<number>();
  const [totalRowSearch, setTotalRowSearch] = useState(false);
  const [, setForceRender] = useState(false);
  const routes = useUserRoutesChildren();
  const [density, setDensity] = useState<'comfortable' | 'compact'>(
    'comfortable',
  );
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const { init, preferences, pageSize, filterType } = useTableStore();
  const tableStore = useTableStore((state: any) => state);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: pageSize || 10,
  });
  const [panelOpen, setPanelOpen] = useState(false);
  const [anchorEls, setAnchorEls] = useState<{
    [key: string]: HTMLElement | null;
  }>({
    open1: null,
    open2: null,
    open3: null,
    open4: null,
  });
  const open = Boolean(anchorEls.open1);
  const open2 = Boolean(anchorEls.open2);
  const open3 = Boolean(anchorEls.open3);
  const open4 = Boolean(anchorEls.open4);
  const id = open ? 'density-popover' : undefined;

  const handleOpen = (
    popover: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEls((prev) => ({ ...prev, [popover]: event.currentTarget }));
  };

  const handleClose = (popover: string) => {
    setAnchorEls((prev) => ({ ...prev, [popover]: null }));
  };

  const {
    data: { data = [], rowCount } = {},
    isError,
    isRefetching,
    isLoading,
    isSuccess: isDataSuccess,
  } = useQuery<ApiResponse>({
    queryKey: [
      tableName,
      pagination.pageSize,
      limit,
      sorting,
      columnFilters,
      globalFilter,
    ],

    queryFn: async () => {
      const params = {
        offset: pagination.pageIndex * pagination.pageSize,
        size: limit > pagination.pageSize ? limit : pagination.pageSize,
        filters: columnFilters ?? [],
        globalFilter: globalFilter ?? [],
        sorting: sorting ?? [],
      };
      //await new Promise((resolve) => setTimeout(resolve, 5000));
      const data = await service({ params });
      const json = data.response as ApiResponse;
      return json;
    },
    placeholderData: keepPreviousData,
    keepPreviousData: true,
    ...propsQuery,
  });

  useEffect(() => {
    init({
      pageSize: pagination.pageSize,
      filterType: filterType,
    });
  }, [pagination.pageSize, filterType]);

  const {
    data: { totalRow } = {},
    isSuccess: isTotalRowSuccess,
    isLoading: isTotalRowLoading,
  } = useQuery<TotalRowResponse>({
    queryKey: [tableName, 'totalRow'],
    queryFn: async () => {
      const data = await fetchTableRows(tableName);
      const json = data as TotalRowResponse;

      return json;
    },
    enabled:
      totalRowSearch &&
      rowCount &&
      (rowCount > pagination.pageSize || limit < rowCount),
    ...propsQuery,
  });

  useEffect(() => {
    if (isTotalRowSuccess && totalRow && totalRow > limit) {
      setLimit((prev) => prev + 400);
    }
  }, [isTotalRowSuccess]);

  useEffect(() => {
    if (isDataSuccess && isTotalRowSuccess) {
      setTimeout(() => {
        setPagination((prev) => ({
          ...prev,
          pageIndex: savedPagination || 0,
        }));
      }, 200);
    }
  }, [isDataSuccess, isTotalRowSuccess]);

  useEffect(() => {
    if (tableStore.pageSize !== pagination.pageSize) {
      preferences({
        ...tableStore,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageSize]);

  useEffect(() => {
    setForceRender((prev) => !prev);
  }, []);

  const handleExportCsv = useCallback(
    (rows: MRT_Row<any>[], columns: any[]) => {
      const filteredColumns = columns.filter(
        (column) =>
          !['created_at', 'created_by', 'updated_by', 'updated_at'].includes(
            column.id,
          ),
      );

      const data = rows.map((row) => {
        const newRow: { [key: string]: any } = {};
        filteredColumns.forEach((column) => {
          newRow[column.header] = row.original[column.id];
        });
        return newRow;
      });

      const csv = generateCsv(csvConfig)(data);

      download(csvConfig)(csv);
    },
    [columns],
  );

  const handleExportPdf = useCallback(
    (rows: MRT_Row<any>[]) => {
      const doc = new jsPDF();

      const tableData = rows.map((row) => Object.values(row.original));

      const tableHeaders = columns.map((c: any) => c.header);

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData as RowInput[],
      });

      doc.save(`${exportOptions.fileName}.pdf`);
    },
    [columns],
  );

  const handleDensityChange = () => {
    const newDensity = density === 'comfortable' ? 'compact' : 'comfortable';
    setDensity(newDensity);
    table.setDensity(newDensity);
  };

  const filteredMenus = menuItems?.filter((menu) =>
    routes.some((route) => route.path === menu.route || !menu.route),
  );

  const table = useMaterialReactTable({
    // LANGUAGE
    localization: MRT_Localization_PT_BR,

    // COLUMNS
    columns,
    enableColumnResizing: true,
    enableColumnPinning: true,
    enableColumnOrdering: true,
    layoutMode: 'grid',
    columnFilterDisplayMode: 'custom',
    muiFilterTextFieldProps: ({ column }) => ({
      label: `Filtrar por ${column.columnDef.header}`,
    }),
    // DATA
    data,

    // PROPS
    ...props,

    // INITIAL STATE
    initialState: {
      ...propsInitial,
      showColumnFilters: false,
      columnPinning: {
        right: ['mrt-row-actions'],
      },
      columnSizing: {
        'mrt-row-actions': 70,
      },
      pagination: {
        pageIndex: 0,
        pageSize: pagination.pageSize,
      },
    },

    // HEADER
    muiTableHeadCellProps() {
      return {
        sx: (theme) => ({
          fontWeight: 'bold',
          fontSize: '16px',
          color: theme.palette.text.primary,
          backgroundColor: (theme.palette.text as any).header,
        }),
      };
    },

    renderColumnActionsMenuItems: ({ internalColumnMenuItems }) => {
      const keysToExclude = ['0', '1', '2', '10'];
      return internalColumnMenuItems.filter(
        (item) => !keysToExclude.includes((item as any).key),
      );
    },

    // FILTERING, PAGINATION, SORTING
    pageCount: Math.ceil((rowCount || 1) / pagination.pageSize),
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    paginationDisplayMode: 'pages',
    enableFilters: false,
    muiPaginationProps: {
      color: 'primary',
      rowsPerPageOptions: [10, 20, 50, 100, 250, 400],
      shape: 'rounded',
      variant: 'text',
    },

    // EVENTS
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Erro ao carregar os dados. Tente novamente.',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: rowCount ?? 0,

    muiSkeletonProps: {
      animation: 'wave',
    },
    muiLinearProgressProps: {
      color: 'primary',
    },
    muiCircularProgressProps: {
      color: 'primary',
    },

    // STATE
    state: {
      columnFilters,
      globalFilter,
      pagination,
      showAlertBanner: isError,
      isLoading: isLoading,
      showProgressBars: isRefetching,
      sorting,
    },

    // ROW
    enableRowVirtualization: true,
    muiTableBodyRowProps() {
      return {
        sx: (theme) => ({
          backgroundColor: theme.palette.mode == 'light' ? '#fff' : '#131313',
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        }),
      };
    },
    getRowId: (row) => row.id,
    positionActionsColumn: 'last',
    enableRowActions: false,
    ...(filteredMenus &&
      filteredMenus.length > 0 && {
        enableRowActions: true,
        renderRowActionMenuItems: ({ row, closeMenu }) => [
          <Box key="rowActionMenuItems">
            {filteredMenus.map((menu, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  menu.action(row.original);
                  closeMenu();
                }}
              >
                {menu.getIcon(row.original)}
                <Box component="span" sx={{ marginLeft: '5px' }}>
                  {menu.getLabel(row.original)}
                </Box>
              </MenuItem>
            ))}
          </Box>,
        ],
      }),
    // END ROW
  });

  const [allHeaders] = useState(table.getCenterLeafHeaders());

  return (
    <Card>
      <Stack spacing={2}>
        <Stack
          direction="row"
          alignItems={'center'}
          flexWrap="wrap"
          spacing={1}
          p={1}
        >
          {/* FILTRO LINHA */}
          {filterType === 'line' &&
            renderFiltersComponent(
              'line',
              table,
              customFilters,
              setColumnFilters,
            )}
          {columnFilters.length > 0 && filterType === 'line' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  table.getLeafHeaders().forEach((header) => {
                    if (header.id !== 'mrt-row-actions') {
                      header.column.setFilterValue('');
                    }
                  });
                }}
              >
                Limpar Filtros
              </Button>
            </motion.div>
          )}

          {/* FIM FILTRO LINHA */}

          {/* EXIBIR/OCULTAR COLUNAS */}
          <Stack
            width="100%"
            direction="row"
            justifyContent="end"
            spacing={1}
            p={1}
          >
            <Stack width="100%" direction="row" justifyContent="end">
              <Tooltip
                title="Exibir/Ocultar Colunas"
                color="default"
                placement="top"
              >
                <IconButton
                  onClick={(event) => handleOpen('open4', event)}
                  color="default"
                >
                  <ViewColumnRoundedIcon />
                </IconButton>
              </Tooltip>
              <Popover
                open={open4}
                anchorEl={anchorEls.open4}
                onClose={() => handleClose('open4')}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ColumnVisibilityToggle headers={allHeaders} />
              </Popover>
            </Stack>

            {filterType === 'drawer' && (
              <Tooltip title="Filtros" color="default" placement="top">
                <IconButton
                  onClick={() => setPanelOpen(!panelOpen)}
                  color="default"
                >
                  <FilterListRoundedIcon />
                </IconButton>
              </Tooltip>
            )}

            {filterType === 'popover' && (
              <Tooltip title="Filtros" color="default" placement="top">
                <IconButton
                  onClick={(event) => handleOpen('open3', event)}
                  color="default"
                >
                  <FilterAltRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
            <Popover
              open={open3}
              anchorEl={anchorEls.open3}
              onClose={() => handleClose('open3')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={2}
                pt={2}
                pr={2}
                pl={2}
                pb={1}
                width="300px"
                maxWidth="300px"
              >
                <FilterAltRoundedIcon />
                <Typography variant="h6">Filtros</Typography>
              </Stack>
              <Divider />
              <Stack
                pt={1}
                pr={1}
                pl={2}
                justifyContent={'center'}
                width="300px"
                maxWidth="300px"
              >
                {filterType === 'popover' &&
                  renderFiltersComponent(
                    'popover',
                    table,
                    customFilters,
                    setColumnFilters,
                  )}
              </Stack>
              <Stack justifyContent="center" p={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    table.getLeafHeaders().forEach((header) => {
                      if (header.id !== 'mrt-row-actions') {
                        header.column.setFilterValue('');
                      }
                    });
                  }}
                >
                  Limpar
                </Button>
              </Stack>
            </Popover>
            {/* FIM FILTRO POPOVER */}

            {/* EXPORTAR */}
            {exportOptions && (
              <>
                <IconButton
                  onClick={(event) => handleOpen('open2', event)}
                  color="default"
                >
                  <Tooltip title="Exportar" placement="top">
                    <FileDownloadRoundedIcon />
                  </Tooltip>
                </IconButton>
                <Menu
                  anchorEl={anchorEls.open2}
                  open={open2}
                  onClose={() => handleClose('open2')}
                >
                  {csv && (
                    <MenuItem
                      onClick={() =>
                        handleExportCsv(
                          table.getPaginationRowModel().rows,
                          columns,
                        )
                      }
                    >
                      Exportar em CSV
                    </MenuItem>
                  )}
                  {pdf && (
                    <MenuItem
                      onClick={() =>
                        handleExportPdf(table.getPaginationRowModel().rows)
                      }
                    >
                      Exportar em PDF
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
            {/* FIM EXPORTAR */}

            <Tooltip title="Opções da tabela" placement="top">
              <IconButton
                onClick={(event) => handleOpen('open1', event)}
                color="default"
              >
                <MoreVertRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEls.open1}
            onClose={() => handleClose('open1')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ padding: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={density === 'compact'}
                    onChange={handleDensityChange}
                    icon={<DensityMedium />}
                    checkedIcon={<ViewCompact />}
                    color="primary"
                  />
                }
                label="Densidade"
              />
            </Box>
          </Popover>
          {/* EXIBIR/OCULTAR COLUNAS */}
        </Stack>

        {/* TABELA */}
        <MRT_TableContainer table={table} />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="inherit">
              Mostrando de {pagination.pageIndex * pagination.pageSize + 1} até{' '}
              {(pagination.pageIndex + 1) * pagination.pageSize <
              (rowCount || 0)
                ? (pagination.pageIndex + 1) * pagination.pageSize
                : rowCount}{' '}
              de{' '}
              {(totalRow || 0) > 0
                ? totalRow
                : rowCount &&
                    (rowCount < pagination.pageSize || limit > rowCount)
                  ? rowCount
                  : 'muitos'}{' '}
              registros
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <MRT_TablePagination table={table} />
            <Button
              variant={'text'}
              disabled={
                limit > (totalRow || 400) ||
                (rowCount !== undefined &&
                  (rowCount < pagination.pageSize || limit > rowCount))
              }
              onClick={() => {
                setTotalRowSearch(true);
                setSavedPagination(pagination.pageIndex);
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: 0,
                }));
              }}
            >
              {rowCount &&
              (rowCount < pagination.pageSize || limit > rowCount) ? (
                'Exibindo todos os registros'
              ) : isTotalRowLoading ? (
                <LinearProgress style={{ width: '80%' }} />
              ) : limit > (totalRow || 400) ? (
                'Exibindo todos os registros'
              ) : totalRowSearch ? (
                <LinearProgress style={{ width: '80%' }} />
              ) : (
                'Exibir tudo'
              )}
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Drawer
        sx={{ width: '100px' }}
        variant="temporary"
        anchor={'right'}
        open={panelOpen}
        onClose={() => setPanelOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box sx={{ width: sideNavWidth }}>
          <Stack direction="row" justifyItems="center" spacing={2} p={2}>
            <FilterListRoundedIcon />
            <Typography variant="h6">Filtros</Typography>
          </Stack>
          <Divider />
          <Box p={2}>
            {filterType === 'drawer' &&
              renderFiltersComponent(
                'drawer',
                table,
                customFilters,
                setColumnFilters,
              )}
          </Box>
          <Stack direction="row" justifyContent="end" p={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                table.getLeafHeaders().forEach((header) => {
                  if (header.id !== 'mrt-row-actions') {
                    header.column.setFilterValue('');
                  }
                });
              }}
            >
              Limpar
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </Card>
  );
};

export default CreateList;

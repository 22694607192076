import { request } from '@core/data/request';

export interface UserChangePasswordParams {
  password?: string;
  newPassword?: string;
}

export const changePasswordUser = async (
  userData: UserChangePasswordParams,
) => {
  return request<UserChangePasswordParams>({
    method: 'post',
    url: '/user/update-password',
    data: {
      ...userData,
    },
  });
};

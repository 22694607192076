import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from '@core/auth/AuthGuard';
import Loadable from '@core/components/LayoutTheme/Loadable';
import AlphaLayout from '@core/components/LayoutTheme/AlphaLayout/AlphaLayout';
import IlogixModule from '@ilogix/IlogixModule';

// session pages
const NotFound = Loadable(
  lazy(() => import('./modules/ilogix/views/session/NotFound')),
);
const JwtLogin = Loadable(
  lazy(() => import('./modules/ilogix/views/auth/pages/JwtLoginPage')),
);
const JwtActiveAccount = Loadable(
  lazy(() => import('./modules/ilogix/views/auth/pages/JwtActiveAccountPage')),
);

const routes = [
  {
    element: (
      <AuthGuard>
        <AlphaLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/*',
        element: <IlogixModule />,
      },
    ],
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  {
    path: '/session/newUserAccount/:token',
    element: <JwtActiveAccount expire={false} />,
  },
  {
    path: '/session/activateAccount/:token',
    element: <JwtActiveAccount expire={true} />,
  },

  { path: '/', element: <Navigate to="/blank" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;

import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { AlphaContentBox } from '@core/components/Layout';
import { Breadcrumb } from '@core/components/LayoutTheme';
//import { LineChart } from '@mui/x-charts/LineChart';

const IntegrationDashboardPage: React.FC = () => {
  const onlineUsers = 2;
  const connectedMachines = 2;
  const offlineUsers = 20;

  return (
    <AlphaContentBox>
      <Breadcrumb
        routeSegments={[{ name: 'Dashboard' }, { name: 'Integrações' }]}
      />
      <Box mt={3}>
        <Box sx={{ padding: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Usuários Online</Typography>
                  <Typography variant="h4" color="primary">
                    {onlineUsers}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Máquinas Conectadas</Typography>
                  <Typography variant="h4" color="primary">
                    {connectedMachines}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Usuários Offline</Typography>
                  <Typography variant="h4" color="primary">
                    {offlineUsers}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AlphaContentBox>
  );
};

export default IntegrationDashboardPage;

import { useEffect } from 'react';
import { loginAuth, activeAccountAuth } from '@ilogix/views/auth/services';
import useAuthStore from '@core/store/authStore';
import { fetchUserRoutes } from '@core/services/routeService';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

interface User {
  id: string;
  name: string;
  email: string;
}

interface TokenPayload extends JwtPayload {
  id: string;
  name: string;
  email: string;
}

const setSession = (accessToken: string): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('redirectUrl');
  }
};

const decodeToken = (accessToken: string): User => {
  const decodedToken = jwtDecode<TokenPayload>(accessToken);
  const { id, name, email } = decodedToken;

  return {
    id,
    name,
    email,
  };
};

const useAuth = () => {
  const { init, login, logout, accessToken } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const storedAccessToken = accessToken;
      if (storedAccessToken) {
        const user = decodeToken(storedAccessToken);
        init({
          isAuthenticated: true,
          user,
          accessToken: storedAccessToken,
        });
      } else {
        init({ isAuthenticated: false, user: null, accessToken: null });
      }
    })();
  }, [init]);

  const handleLogin = async (
    email: string,
    password: string,
    userLoginToken: string,
  ): Promise<void> => {
    const jwtToken = await loginAuth(email, password, userLoginToken);

    const { response } = jwtToken.data;

    const user = decodeToken(response);

    login(user, response);

    await fetchUserRoutes();
  };

  const handleActivateAccount = async (password: string): Promise<void> => {
    await activeAccountAuth(password);
  };

  const handleLogout = async () => {
    setSession('');
    localStorage.removeItem('routes');
    logout();
    navigate('/session/signin', { replace: true, state: { from: '/' } });
  };

  return { handleLogin, handleLogout, handleActivateAccount };
};

export { useAuth };

import { themeShadows } from './themeColors';

export const components = {
  MuiPopover: {
    styleOverrides: {
      paper: ({ theme }: { theme: any }) => ({
        borderRadius: 8,
        padding: 0,
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0px 6px 30px rgba(0, 0, 0, 0.5)'
            : '0px 6px 20px rgba(0, 0, 0, 0.08)',
        border:
          theme.palette.mode === 'dark'
            ? '1px solid #32383e'
            : '1px solid #dcdfe4',
        backgroundColor:
          theme.palette.mode === 'dark' ? '#121517' : 'rgb(255, 255, 255)',
        marginTop: 5,
        transition:
          'opacity 230ms cubic-bezier(0.4, 0, 0.2, 1), transform 153ms cubic-bezier(0.4, 0, 0.2, 1)',
      }),
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        tableLayout: 'fixed',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: '13px',
        padding: '12px 0px',
      },
      root: {
        fontSize: '14px',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        padding: '12px 8px 12px 0px',
      },
    },
  },
  MUIDataTableSelectCell: {
    styleOverrides: {
      root: {
        paddingLeft: 12,
      },
    },
  },
  MUIDataTableHeadCell: {
    styleOverrides: {
      root: {
        paddingLeft: 16,
      },
    },
  },
  MUIDataTableBodyCell: {
    styleOverrides: {
      root: {
        paddingLeft: 8,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        textTransform: 'none',
        fontWeight: '400',
      },
      contained: {
        boxShadow: themeShadows[8],
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
      },
      a: {
        textDecoration: 'none',
        color: 'inherit',
      },
      '#root': {
        height: '100%',
      },
      '#nprogress .bar': {
        zIndex: '2000 !important',
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: themeShadows[12],
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '11px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }: { theme: any }) => ({
        fontSize: '0.875rem',
        '&:hover': {
          borderRadius: '8px',
          padding: '6px 16px',
        },
        '&.Mui-selected': {
          backgroundColor:
            theme.palette.mode == 'light'
              ? 'rgba(196, 226, 255, 0.47)'
              : 'rgba(117, 120, 255, 0.16)',
          borderRadius: '8px',
          padding: '6px 14px',
          margin: '2px 0',
        },
      }),
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: '6px 5px',
      },
    },
  },
  MuiExpansionPanel: {
    styleOverrides: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }: { theme: any }) => ({
        borderRadius: '8px',
        boxShadow:
          'rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
        backgroundColor:
          theme.palette.mode == 'light' ? theme.palette.paper : '#131313',
      }),
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ theme }: { theme: any }) => ({
        /* '.MuiOutlinedInput-root': {
          fontSize: '0.975rem',
        }, */
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#d3d3d3' : '#464646',
        },
      }),
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      noOptionsText: 'Sem opções', // Define o texto padrão quando não há opções
    },
  },
};

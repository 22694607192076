import { Route, Routes } from 'react-router-dom';
import { AuthorizedPaths } from './path';
import { Route as InterfaceRoute } from '@core/data/roles';
import RouteErrorBoundary from '@core/components/ErrorBoundary/RouteErrorBoundary';

const IlogixModule = () => {
  const ilogixRoutes = AuthorizedPaths();

  return (
    <RouteErrorBoundary>
      <Routes>
        {ilogixRoutes.map((route: InterfaceRoute, index: number) => (
          <Route key={index} path={route.path} element={<route.element />} />
        ))}
      </Routes>
    </RouteErrorBoundary>
  );
};

export default IlogixModule;

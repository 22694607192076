import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { AlphaTheme } from '@core/components/LayoutTheme';
import routes from './routes';
import ErrorBoundary from '@core/components/ErrorBoundary/ErrorBoundary';
import { SettingsProvider } from '@ilogix/contexts/SettingsContext';
import { ToastConfig } from '@core/components/Toast';

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AlphaTheme>
        <CssBaseline />
        <ToastConfig />
        <ErrorBoundary>{content}</ErrorBoundary>
      </AlphaTheme>
    </SettingsProvider>
  );
};

export default App;

import { Button, Divider, Grid } from '@mui/material';
import { MenuItemProps } from '@core/components/DataTable/CreateList';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';

import { fetchListGroups } from '../services/groupService';
import { useMemo } from 'react';
import { AlphaContentBox } from '@core/components/Layout';
import { NavLink, useNavigate } from 'react-router-dom';
import { Breadcrumb } from '@core/components/LayoutTheme';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { lazy } from 'react';
import Loadable from '@core/components/LayoutTheme/Loadable';

const CreateList = Loadable(
  lazy(() => import('@core/components/DataTable/CreateList')),
);

const GroupListPage = () => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        size: 50,
        hideFilter: true,
      },
      {
        id: 'name',
        header: 'Nome',
        accessorKey: 'name',
        filterVariant: 'text',
      },
    ],
    [],
  );

  const menus: MenuItemProps[] = [
    {
      getLabel: () => 'Gerenciar',
      action: (row) => navigate(`/accessControl/manageGroup/manage/${row.id}`),
      getIcon: () => <TuneRoundedIcon />,
      route: '/accessControl/manageGroup/manage',
    },
    {
      getLabel: () => 'Editar',
      action: (row) => navigate(`/accessControl/manageGroup/edit/${row.id}`),
      getIcon: () => <EditRoundedIcon />,
      route: '/accessControl/manageGroup/edit',
    },
  ];

  return (
    <AlphaContentBox>
      <Breadcrumb
        routeSegments={[
          { name: 'Controle de Acesso' },
          { name: 'Gerenciar Grupos' },
        ]}
      />
      <Grid container spacing={3} alignItems="center" mb={2} mt={1}>
        <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
          <NavLink to="/accessControl/manageGroup/add">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddRoundedIcon />}
            >
              Cadastrar
            </Button>
          </NavLink>
        </Grid>
      </Grid>
      <Divider />
      <Grid pt={4}>
        <CreateList
          tableName="group"
          service={fetchListGroups}
          columns={columns}
          menuItems={menus}
          propsQuery={{
            staleTime: 1000 * 60 * 3, // 3 minutes
          }}
        />
      </Grid>
    </AlphaContentBox>
  );
};

export default GroupListPage;

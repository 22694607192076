import { request, requestTable } from '@core/data/request';
import { DataTableParams } from '@core/components/DataTable/CreateList';

export interface GroupParams {
  id?: string | number;
  name?: string;
}

export const fetchListGroups = (params: DataTableParams) =>
  requestTable({ endpoint: '/group/list-all-groups', ...params });

export const fetchAllGroups = async (): Promise<GroupParams[]> => {
  return request<GroupParams[]>({
    method: 'get',
    url: '/group/view-groups',
  });
};

export const registerGroup = async (groupData: GroupParams) => {
  return request<GroupParams>({
    method: 'post',
    url: '/group/register-group',
    data: {
      ...groupData,
    },
  });
};

export const fetchGroup = async (id: number | string): Promise<GroupParams> => {
  return request<GroupParams>({
    method: 'get',
    url: `/group/edit-group/${id}`,
  });
};

export const editGroup = async (
  id: number | string,
  groupData: GroupParams,
) => {
  return request<GroupParams>({
    method: 'put',
    url: `/group/edit-group/${id}`,
    data: {
      ...groupData,
    },
  });
};

import { request, requestTable } from '@core/data/request';
import { DataTableParams } from '@core/components/DataTable/CreateList';

export interface UserParams {
  id?: number | string;
  name?: string;
  email?: string;
  group_id?: string;
  active?: boolean;
}

export const fetchListUsers = (params: DataTableParams) =>
  requestTable({ endpoint: '/user/list-all-users', ...params });

export const registerUser = async (userData: UserParams) => {
  return request<UserParams>({
    method: 'post',
    url: '/user/register-user',
    data: {
      ...userData,
    },
  });
};

export const fetchUser = async (id: number | string): Promise<UserParams> => {
  return request<UserParams>({
    method: 'get',
    url: `/user/edit-user/${id}`,
  });
};

export const editUser = async (id: number | string, userData: UserParams) => {
  return request<UserParams>({
    method: 'put',
    url: `/user/edit-user/${id}`,
    data: {
      ...userData,
    },
  });
};

export const toggleUserStatus = async (
  id: number | string,
  active: boolean,
) => {
  return request<UserParams>({
    method: 'patch',
    url: `/user/activate-user/${id}`,
    data: {
      active,
    },
  });
};

import { request, requestTable } from '@core/data/request';
import { DataTableParams } from '@core/components/DataTable/CreateList';

export interface RegisterCompanyParams {
  name: string;
  address: string;
  telephone: string;
  state_id: number;
  city_id: number;
  director_contact_id: number;
  neighborhood_id: number;
  matrix_id: number;
  nickname: string;
  logo?: File | null | undefined;
  pmc: boolean | number;
  cep: string;
  city?: CityParams;
}

export interface DirectorParams {
  id: number;
  name: string;
  email: string;
}

export interface MatrixParams {
  id: number;
  name: string;
}

export interface StateParams {
  id: number;
  name: string;
  uf: string;
}

export interface CityParams {
  id: number;
  state_id: number;
  name: string;
}

export interface NeighborhoodParams {
  id: number;
  city_id: number;
  name: string;
}

export const fetchListCompany = (params: DataTableParams) =>
  requestTable({ endpoint: '/company/list-all-companies', ...params });

export const registerCompany = async (companyData: RegisterCompanyParams) => {
  return request<RegisterCompanyParams>({
    method: 'post',
    url: '/company/register-company',
    data: {
      ...companyData,
    },
  });
};

export const fetchCompany = async (
  id: number | string,
): Promise<RegisterCompanyParams> => {
  return request<RegisterCompanyParams>({
    method: 'get',
    url: `/company/edit-company/${id}`,
  });
};

export const editCompany = async (
  id: number | string,
  companyData: RegisterCompanyParams,
) => {
  return request<RegisterCompanyParams>({
    method: 'put',
    url: `/company/edit-company/${id}`,
    data: {
      ...companyData,
    },
  });
};

export const fetchAllDirectors = async (): Promise<DirectorParams[]> => {
  return request<DirectorParams[]>({
    method: 'get',
    url: '/director/view-directors-contacts',
  });
};

export const fetchAllMatrices = async (): Promise<MatrixParams[]> => {
  return request<MatrixParams[]>({
    method: 'get',
    url: '/matrix/view-matrices',
  });
};

export const fetchAllState = async (): Promise<StateParams[]> => {
  return request<StateParams[]>({
    method: 'get',
    url: '/location/view-states',
  });
};

export const fetchCityByState = async ({
  state_id,
}: {
  state_id: number;
}): Promise<CityParams[]> => {
  return request<CityParams[]>({
    method: 'get',
    url: `/location/view-cities/${state_id}`,
  });
};

export const fetchNeighborhoodByCity = async ({
  city_id,
}: {
  city_id: number;
}): Promise<NeighborhoodParams[]> => {
  return request<NeighborhoodParams[]>({
    method: 'get',
    url: `/location/view-neighborhoods/${city_id}`,
  });
};

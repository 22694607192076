import { HeaderForm } from '@core/components/Form';
import { AlphaContentBox } from '@core/components/Layout';
import { BreadcrumbForm } from '@core/components/LayoutTheme';
import { Box, Button, Divider, Grid } from '@mui/material';
import { useState } from 'react';
import GroupManageColumns from '../components/GroupManageColumns';

const GroupManagePage = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  /* const columns = [
    {
      id: 'name',
      header: 'Nome',
      accessorKey: 'name',
    },
    {
      id: 'menu_id',
      header: 'Menu',
      accessorKey: 'menu_id',
    },
  ]; */

  return (
    <AlphaContentBox>
      <BreadcrumbForm
        routeSegments={[
          { name: 'Gerenciar Grupos', path: '/accessControl/manageGroup' },
        ]}
      />
      <Grid container spacing={3} alignItems="center" pl={6} pr={6} pt={6}>
        <Grid item xs={12}>
          <HeaderForm title="Grupo" />
          <Divider />
        </Grid>
      </Grid>
      <Box mt={2} pl={6} pr={6}>
        <Button onClick={() => handleOpenModal()} variant="contained">
          Gerenciar Colunas
        </Button>
        {/* <CreateList
          service={fetchFakeGroups}
          tableName="manage-group-columns"
          columns={columns}
          props={{
            enableGrouping: true,
            groupedColumnMode: 'remove',
          }}
          propsInitial={{
            expanded: true,
            grouping: ['children'],
          }}
        /> */}
      </Box>
      {openModal && (
        <GroupManageColumns open={openModal} onClose={handleCloseModal} />
      )}
    </AlphaContentBox>
  );
};

export default GroupManagePage;

import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const StyledContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

interface ContentBoxProps {
  children: React.ReactNode;
  className?: string;
}

const AlphaContentBox: React.FC<ContentBoxProps> = ({
  children,
  className,
}) => {
  return (
    <StyledContentBox className={clsx(className)}>{children}</StyledContentBox>
  );
};

export default AlphaContentBox;

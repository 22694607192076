import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  Grid,
  Box,
  TextField,
  Typography,
  Divider,
  Card,
  Autocomplete,
  styled,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { BreadcrumbForm } from '@core/components/LayoutTheme';
import * as z from 'zod';
import { AlphaContentBox } from '@core/components/Layout';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { CustomMask } from '@core/components/Mask';
import {
  DirectorParams,
  editCompany,
  fetchAllDirectors,
  fetchAllMatrices,
  fetchAllState,
  fetchCityByState,
  fetchCompany,
  fetchNeighborhoodByCity,
  MatrixParams,
  registerCompany,
  RegisterCompanyParams,
  StateParams,
} from '../services/companyService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { HeaderForm } from '@core/components/Form';
import { fetchAddressByCep } from '@core/services/viacepService';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const companySchema = z.object({
  name: z.string({ required_error: 'Nome é obrigatório' }),
  address: z.string({ required_error: 'Endereço é obrigatório' }),
  telephone: z
    .string({ required_error: 'Telefone é obrigatório' })
    .min(14, 'Telefone deve ter 14 caracteres'),
  state_id: z.number({ required_error: 'Estado é obrigatório' }),
  city_id: z.number({ required_error: 'Cidade é obrigatória' }),
  director_contact_id: z.number({ required_error: 'Diretor é obrigatório' }),
  neighborhood_id: z.number({ required_error: 'Bairro é obrigatório' }),
  matrix_id: z.number({ required_error: 'Matriz é obrigatória' }),
  nickname: z.string().optional(),
  logo: z.any().optional(),
  pmc: z.boolean(),
  cep: z
    .string({ required_error: 'CEP é obrigatório' })
    .min(8, 'CEP deve ter pelo menos 8 dígitos'),
});

const initialValues = {
  name: '',
  address: '',
  telephone: '',
  state_id: 0,
  city_id: 0,
  director_contact_id: 0,
  neighborhood_id: 0,
  matrix_id: 0,
  nickname: '',
  logo: null as File | null | undefined,
  pmc: false,
  cep: '',
};

const CompanyFormPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const isEditing = !!id;
  const [isCityEnabled, setIsCityEnabled] = useState(false);
  const [isNeighborhoodEnabled, setIsNeighborhoodEnabled] = useState(false);

  const [selectedStateId, setSelectedStateId] = useState<number | null>(null);
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null);

  const { data: directors } = useQuery<DirectorParams[]>({
    queryKey: ['directors'],
    queryFn: fetchAllDirectors,
  });
  const { data: matrices } = useQuery<MatrixParams[]>({
    queryKey: ['matrices'],
    queryFn: fetchAllMatrices,
  });
  const { data: states } = useQuery<StateParams[]>({
    queryKey: ['states'],
    queryFn: fetchAllState,
  });
  const { data: cities } = useQuery({
    queryKey: ['cities', selectedStateId],
    queryFn: () => fetchCityByState({ state_id: selectedStateId! }),
    enabled: !!selectedStateId,
  });
  const { data: neighborhoods } = useQuery({
    queryKey: ['neighborhoods', selectedCityId],
    queryFn: () => fetchNeighborhoodByCity({ city_id: selectedCityId! }),
    enabled: !!selectedCityId,
  });

  const mutationCreate = useMutation({
    mutationFn: (params: { values: RegisterCompanyParams }) =>
      registerCompany(params.values),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['company'],
      });
    },
    onError: (error: any) => {
      console.error('Error create company:', error);
    },
  });

  const handleCreate = async (values: RegisterCompanyParams) => {
    try {
      mutationCreate.mutate({ values });

      navigate(-1);
    } catch (error) {
      console.error('Failed to register company:', error);
    }
  };

  const mutationUpdate = useMutation({
    mutationFn: (params: { id: string; values: RegisterCompanyParams }) =>
      editCompany(params.id, params.values),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['company'],
      });
    },
    onError: (error: any) => {
      console.error('Error updating company:', error);
    },
  });

  const handleUpdate = (id: string, values: RegisterCompanyParams) => {
    mutationUpdate.mutate({ id, values });
    navigate(-1);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(companySchema),
    onSubmit: async (values) => {
      if (isEditing) {
        handleUpdate(id, values);
      } else {
        handleCreate(values);
      }
    },
  });

  const { data: company } = useQuery<RegisterCompanyParams>({
    queryKey: ['fetchCompany', id],
    queryFn: () => {
      if (id !== undefined) {
        return fetchCompany(id);
      }
      return Promise.reject('ID is undefined');
    },
    enabled: isEditing && id !== undefined,
  });

  useEffect(() => {
    if (company) {
      formik.setValues({
        ...company,
        state_id: company.city?.state_id ?? 0,
        pmc: company.pmc === 1 ? true : false,
        logo: company.logo ?? null,
      });

      setSelectedStateId(company.city?.state_id ?? 0);
      setSelectedCityId(company.city_id);
      setIsCityEnabled(true);
      setIsNeighborhoodEnabled(true);
    }
  }, [company]);

  const cep = formik.values.cep.replace(/\D/g, '');
  const useFetchAddressByCep = (cep: string) => {
    return useQuery({
      queryKey: ['address', cep],
      queryFn: async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));

        return fetchAddressByCep(cep);
      },
      enabled: cep.length === 8 && !isEditing,
      staleTime: 1000 * 60 * 10,
      retry: false,
    });
  };
  const { data: cepDetails, isFetching: isFetchingCep } =
    useFetchAddressByCep(cep);

  useEffect(() => {
    if (cepDetails) {
      formik.setFieldValue('address', cepDetails?.logradouro ?? '');
    }
  }, [cepDetails]);

  return (
    <AlphaContentBox>
      <BreadcrumbForm
        routeSegments={[
          {
            name: 'Gerenciar Empresas',
            path: '/accessControl/manageCompany',
          },
        ]}
      />
      <Grid container spacing={3} alignItems="center" p={6}>
        <Grid item xs={12}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            {isEditing ? (
              <HeaderForm
                title={formik.values?.name}
                subtitle="Editar Empresa"
              />
            ) : (
              <HeaderForm title="Empresa" subtitle="Cadastrar" />
            )}
            <Card elevation={2} sx={{ borderRadius: '10px', padding: '5px' }}>
              <Typography p={2} variant="h6" gutterBottom>
                Informações Básicas
              </Typography>
              <Grid container spacing={2} pl={2} pr={2} pb={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Nome"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="nickname"
                    name="nickname"
                    label="Apelido"
                    value={formik.values.nickname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nickname && Boolean(formik.errors.nickname)
                    }
                    helperText={
                      formik.touched.nickname && formik.errors.nickname
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    id="telephone"
                    name="telephone"
                    label="Telefone"
                    value={formik.values.telephone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      inputComponent: CustomMask as any,
                      inputProps: { mask: '(00) 00000-0000' },
                    }}
                    error={
                      formik.touched.telephone &&
                      Boolean(formik.errors.telephone)
                    }
                    helperText={
                      formik.touched.telephone && formik.errors.telephone
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    fullWidth
                    id="director_contact_id"
                    options={directors || []}
                    getOptionLabel={(option: DirectorParams) => option.name}
                    value={
                      directors?.find(
                        (director: DirectorParams) =>
                          director.id === formik.values.director_contact_id,
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.director_contact_id &&
                          Boolean(formik.errors.director_contact_id)
                        }
                        helperText={
                          formik.touched.director_contact_id &&
                          formik.errors.director_contact_id
                        }
                        label="Diretor"
                      />
                    )}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'director_contact_id',
                        newValue?.id || '',
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    fullWidth
                    id="matrix_id"
                    options={matrices || []}
                    getOptionLabel={(option: MatrixParams) => option.name}
                    value={
                      matrices?.find(
                        (matrix: MatrixParams) =>
                          matrix.id === formik.values.matrix_id,
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.matrix_id &&
                          Boolean(formik.errors.matrix_id)
                        }
                        helperText={
                          formik.touched.matrix_id && formik.errors.matrix_id
                        }
                        label="Matriz"
                      />
                    )}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('matrix_id', newValue?.id || '');
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Button
                      component="label"
                      fullWidth
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      sx={{
                        fontSize: '1rem',
                      }}
                    >
                      Carregar Logo
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => {
                          formik.setFieldValue(
                            'logo',
                            event.currentTarget?.files
                              ? event.currentTarget.files[0]
                              : null,
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Button>
                    {formik.touched.logo && formik.errors.logo && (
                      <Typography variant="caption" color="error">
                        {formik.errors.logo as string}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Box p={2}>
                <Divider />
              </Box>
              <Typography p={2} variant="h6" gutterBottom>
                Dados de Localização
              </Typography>
              <Grid container spacing={2} pl={2} pr={2} pb={2}>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    id="cep"
                    name="cep"
                    label="CEP"
                    disabled={isFetchingCep}
                    value={formik.values.cep}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue('cep', e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {isFetchingCep && <CircularProgress size={24} />}
                        </InputAdornment>
                      ),
                      inputComponent: CustomMask as any,
                      inputProps: {
                        mask: '00000-000',
                      },
                    }}
                    error={formik.touched.cep && Boolean(formik.errors.cep)}
                    helperText={formik.touched.cep && formik.errors.cep}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Endereço"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>

                <Grid item xs={12} md={1}>
                  <Autocomplete
                    fullWidth
                    id="state_id"
                    options={states || []}
                    getOptionLabel={(option: StateParams) => option.uf}
                    value={
                      states?.find(
                        (state: StateParams) =>
                          state.id === formik.values.state_id,
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.state_id &&
                          Boolean(formik.errors.state_id)
                        }
                        helperText={
                          formik.touched.state_id && formik.errors.state_id
                        }
                        label="Estado"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const newStateId = newValue?.id || 0;
                      formik.setFieldValue('state_id', newStateId);
                      setSelectedStateId(newStateId);
                      setIsCityEnabled(!!newStateId);
                      setIsNeighborhoodEnabled(false);
                      if (!newStateId) {
                        formik.setFieldValue('city_id', '');
                        formik.setFieldValue('neighborhood_id', '');
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Autocomplete
                    fullWidth
                    id="city_id"
                    disabled={!isCityEnabled}
                    options={cities || []}
                    value={
                      cities?.find(
                        (city) => city.id == formik.values.city_id,
                      ) || null
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.city_id &&
                          Boolean(formik.errors.city_id)
                        }
                        helperText={
                          formik.touched.city_id && formik.errors.city_id
                        }
                        label="Cidade"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}{' '}
                              {!isCityEnabled && (
                                <InputAdornment position="end">
                                  <InfoRoundedIcon titleAccess="Selecione o Estado" />
                                </InputAdornment>
                              )}
                            </>
                          ),
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      const newCityId = newValue?.id || 0;
                      formik.setFieldValue('city_id', newCityId);
                      setSelectedCityId(newCityId);
                      setIsNeighborhoodEnabled(!!newCityId);
                      if (!newCityId) {
                        formik.setFieldValue('neighborhood_id', '');
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    id="neighborhood_id"
                    disabled={!isNeighborhoodEnabled}
                    options={neighborhoods || []}
                    getOptionLabel={(option) => option.name}
                    value={
                      neighborhoods?.find(
                        (neighborhood) =>
                          neighborhood.id == formik.values.neighborhood_id,
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'neighborhood_id',
                        newValue?.id || '',
                      );
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.neighborhood_id &&
                          Boolean(formik.errors.neighborhood_id)
                        }
                        helperText={
                          formik.touched.neighborhood_id &&
                          formik.errors.neighborhood_id
                        }
                        label="Bairro"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}{' '}
                              {!isNeighborhoodEnabled && (
                                <InputAdornment position="end">
                                  <InfoRoundedIcon titleAccess="Selecione a Cidade" />
                                </InputAdornment>
                              )}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Divider />
              </Grid>
              <Box p={2}>
                <Divider />
              </Box>
              <Typography p={2} variant="h6" gutterBottom>
                Informações Adicionais
              </Typography>
              <Grid container spacing={2} pl={2} pr={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="pmc"
                    name="pmc"
                    label="PMC"
                    select
                    value={formik.values.pmc ? 1 : 0}
                    onChange={(event) => {
                      const value = event.target.value === '1';
                      formik.setFieldValue('pmc', value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.pmc && Boolean(formik.errors.pmc)}
                    helperText={formik.touched.pmc && formik.errors.pmc}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="0" label="Não" />
                    <option value="1" label="Sim" />
                  </TextField>
                </Grid>
              </Grid>
              <Grid item p={2} xs={12} textAlign="right">
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={() => navigate(-1)}
                  sx={{ mr: 2 }}
                >
                  Voltar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    mutationCreate.isPending || mutationUpdate.isPending
                  }
                >
                  {mutationCreate.isPending || mutationUpdate.isPending ? (
                    <CircularProgress size={24} />
                  ) : isEditing ? (
                    'Salvar Alterações'
                  ) : (
                    'Cadastrar'
                  )}
                </Button>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </AlphaContentBox>
  );
};

export default CompanyFormPage;

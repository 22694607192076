import {
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import DoDisturbOnRoundedIcon from '@mui/icons-material/DoDisturbOnRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useState } from 'react';

interface ModalProps {
  row?: any;
  open: boolean;
  onClose: () => void;
}

const styleMotion = {
  position: 'absolute',
  width: 1000,
  height: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
};

const CustomBox = styled(Box)(({ theme }: { theme: any }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2f2f2f' : '#eeecec',
  padding: '0.25rem',
  borderRadius: '0.15rem',
  marginBottom: '0.45rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'box-shadow 0.2s ease',
  willChange: 'transform',
}));

const styleGrab = {
  cursor: 'grab',
  mr: 1,
  ':hover': { color: 'primary.main' },
};

const MotionBox = motion(Box);

const initialColumns = [
  { id: 1, label: 'name', active: 1 },
  { id: 2, label: 'name2', active: 1 },
  { id: 3, label: 'name3', active: 1 },
  { id: 4, label: 'name4', active: 0 },
  { id: 5, label: 'name5', active: 0 },
  { id: 6, label: 'name6', active: 1 },
];
const GroupManageColumns = ({ row, open, onClose }: ModalProps) => {
  const theme = useTheme();
  const [columns, setColumns] = useState(initialColumns);

  const handleToogleColumns = ({ status }: { status: number }) => {
    setColumns(
      columns.map((column) => {
        column.active = status;
        return column;
      }),
    );
  };

  const handleToogleColumn = ({
    status,
    id,
  }: {
    status: number;
    id: number;
  }) => {
    setColumns(
      columns.map((column) => {
        if (column.id === id) {
          column.active = status;
        }
        return column;
      }),
    );
  };

  const handleDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) return;

    const sourceIndex = parseInt(source.droppableId);
    const destinationIndex = parseInt(destination.droppableId);

    const updatedColumns = [...columns];

    const sourceColumns = updatedColumns.filter(
      (column) => column.active === sourceIndex,
    );
    const destinationColumns = updatedColumns.filter(
      (column) => column.active === destinationIndex,
    );

    if (sourceIndex === destinationIndex) {
      const [movedColumn] = sourceColumns.splice(source.index, 1);
      sourceColumns.splice(destination.index, 0, movedColumn);

      updatedColumns.forEach((column, idx) => {
        if (column.active === sourceIndex) {
          updatedColumns[idx] = sourceColumns.shift()!;
        }
      });
    } else {
      const [movedColumn] = sourceColumns.splice(source.index, 1);
      movedColumn.active = destinationIndex;
      destinationColumns.splice(destination.index, 0, movedColumn);

      updatedColumns.forEach((column, idx) => {
        if (column.active === sourceIndex) {
          updatedColumns[idx] = sourceColumns.shift()!;
        } else if (column.active === destinationIndex) {
          updatedColumns[idx] = destinationColumns.shift()!;
        }
      });
    }

    setColumns(updatedColumns);
  };

  const columnActive = columns.filter((column) => column.active == 1);
  const columnInactive = columns.filter((column) => column.active == 0);

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={onClose}
    >
      <MotionBox
        sx={styleMotion}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        <Box
          pl={4}
          pt={3}
          pr={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h5">Master</Typography>
          <CloseRoundedIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
        </Box>
        <Divider sx={{ paddingTop: 1 }} />
        <Box p={4} pt={3} pb={0} display={'flex'}>
          <Typography variant="h5">Componentes</Typography>
        </Box>
        <Box p={4} pb={1} pt={2} display={'flex'} height={'16%'}>
          <Stack
            sx={{
              width: '100%',
              boxShadow: `0px 0px 1px 1px ${theme.palette.divider}`,
              borderRadius: '0.25rem',
              padding: 1,
            }}
          >
            <Box display="flex" alignItems="center" mr={2}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                Tipo 1
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box p={4} pt={0} pb={1} display={'flex'} height={'15%'}>
          <Stack
            sx={{
              width: '100%',
              boxShadow: `0px 0px 1px 1px ${theme.palette.divider}`,
              borderRadius: '0.25rem',
              padding: 1,
            }}
          >
            <Box display="flex" alignItems="center" mr={2}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                Ações
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box p={4} pt={3} pb={0} display={'flex'}>
          <Typography variant="h5">Colunas</Typography>
        </Box>
        <Box p={4} pt={2} display={'flex'} height={'70%'}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Stack
              sx={{
                width: '50%',
                mr: 2,
                boxShadow: `0px 0px 1px 1px ${theme.palette.divider}`,
                borderRadius: '0.25rem',
                padding: 1,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pb={2}
              >
                <Box display="flex" alignItems="center" mr={2}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                    Disponível
                  </Typography>
                </Box>
                <Tooltip placement="top" title="Adicionar todas as colunas">
                  <AddCircleRoundedIcon
                    fontSize="small"
                    color="disabled"
                    sx={{
                      cursor: 'pointer',
                      ':hover': { color: 'primary.main' },
                    }}
                    onClick={() => handleToogleColumns({ status: 1 })}
                  />
                </Tooltip>
              </Box>
              <Droppable droppableId="0">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ flexGrow: 1 }}
                  >
                    {columnInactive.map((column, index) => (
                      <Draggable
                        key={column.id}
                        draggableId={column.label}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <CustomBox
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              backgroundColor: snapshot.isDragging
                                ? 'primary.main'
                                : '',
                              color: snapshot.isDragging ? 'white' : '',
                            }}
                          >
                            <Box display={'flex'} alignItems="center">
                              <DragIndicatorRoundedIcon
                                fontSize="small"
                                color="disabled"
                                sx={styleGrab}
                              />
                              <Typography
                                sx={{ fontWeight: 'bold', fontSize: '1rem' }}
                              >
                                {column.label}
                              </Typography>
                            </Box>
                            <AddCircleOutlineRoundedIcon
                              fontSize="small"
                              color="disabled"
                              sx={{
                                cursor: 'pointer',
                                ':hover': { color: 'primary.main' },
                              }}
                              onClick={() =>
                                handleToogleColumn({ status: 1, id: column.id })
                              }
                            />
                          </CustomBox>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Stack>
            <Stack
              sx={{
                width: '50%',
                boxShadow: `0px 0px 1px 1px ${theme.palette.divider}`,
                borderRadius: '0.25rem',
                padding: 1,
              }}
            >
              <Box
                display={'flex'}
                alignContent={'center'}
                justifyContent={'space-between'}
                pb={2}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  Em Uso
                </Typography>
                <Tooltip placement="top" title="Remover todas as colunas">
                  <CancelRoundedIcon
                    fontSize="small"
                    color="disabled"
                    sx={{
                      cursor: 'pointer',
                      ':hover': { color: 'primary.main' },
                    }}
                    onClick={() => handleToogleColumns({ status: 0 })}
                  />
                </Tooltip>
              </Box>
              <Droppable droppableId="1">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    {columnActive.map((column, index) => (
                      <Draggable
                        key={column.id}
                        draggableId={column.label}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <CustomBox
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              backgroundColor: snapshot.isDragging
                                ? 'primary.main'
                                : '',
                              color: snapshot.isDragging ? 'white' : '',
                            }}
                          >
                            <Box
                              display={'flex'}
                              alignItems="center"
                              sx={{ borderColor: 'primary.main' }}
                            >
                              <DragIndicatorRoundedIcon
                                fontSize="small"
                                color="disabled"
                                sx={styleGrab}
                              />
                              <Typography
                                sx={{ fontWeight: 'bold', fontSize: '1rem' }}
                              >
                                {column.label}
                              </Typography>
                            </Box>
                            <DoDisturbOnRoundedIcon
                              fontSize="small"
                              color="disabled"
                              sx={{
                                cursor: 'pointer',
                                ':hover': { color: 'primary.main' },
                              }}
                              onClick={() =>
                                handleToogleColumn({ status: 0, id: column.id })
                              }
                            />
                          </CustomBox>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Stack>
          </DragDropContext>
        </Box>
        <Box
          pl={4}
          pr={4}
          pb={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          height={'auto'}
          marginTop="auto"
        >
          <Button onClick={onClose} variant="text" color="error">
            Fechar
          </Button>
          <Button
            onClick={() => console.log(columns)}
            variant="contained"
            color="primary"
          >
            Aplicar
          </Button>
        </Box>
      </MotionBox>
    </Modal>
  );
};

export default GroupManageColumns;
